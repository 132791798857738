  import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logoM1 from "assets/images/logo-ministarstva1.jpg";
import logo from "assets/images/cpnLogo.jpg";
import slikaKonkursa from "assets/images/slikaKonkursa.jpg";

const StyledImgVisual = styled.img`
  display: block;
  height: 100%;
  width: 600px;

  @media only screen and (max-width: 486px) {
    width: 100%;
  }
`;

const StyledDivImg = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDivText = styled.div`
  margin-right: 50px;
  width: 50%;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  text-align: center;
  align-items: center;

  

  @media only screen and (max-width: 486px) {
    width: unset;
    margin-right: unset;
  }
`;

const StyledDivWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media only screen and (max-width: 486px) {
    flex-direction: column;
  }
`;

const StyledBotttomDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 50px;

  @media only screen and (max-width: 486px) {
    flex-direction: column;
    align-items: center;
} 
`;

const StyledImg = styled.img`
  height: 95px;
`;

const StyledButtonDiv = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 70px;

  @media only screen and (max-width: 486px) {
    width: unset;
    padding-top: 30px;
  }
`;

const StyledButton = styled.button`
  background-color: #73a089;
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 80px;
  font-size: 26px;
`;

const Dokumenta = styled.div`
  text-align: center;
  > ul {
    display: inline-block;
    margin: 0 auto 2rem auto;
    padding: 0;
    list-style: none;
    > li {
      text-align: left;
      border-bottom: 1px solid #ddd;
      > a {
        display: inline-block;
        padding: 0 1rem;
        color: #779;
        > svg {
          margin-right: 0.25rem;
          color: #972600;
        }
      }
    }
  }
`;

const Konkursi = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
  > li {
    padding: 0.5rem;
    > a {
      display: block;
      padding: 1rem 1.5rem;
      line-height: 1.2;
      background: #fff2f2;
      border-radius: 4px;
      transition: ease-in 0.2s all
      &:hover,
      &:focus {
        color: #333;
        background: #cdc;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.075);
        transform: translateY(-0.25rem);
      }
      &:active {
        background: #aba;
        transform: translateY(0);
      }
      > span {
        display: block;
        margin-bottom: 0.5rem;
        color: #666;
        font-weight: bold;
        font-size: 0.875rem;
      }
      small {
        display: block;
        margin-top: 0.25rem;
        color: #888;
      }
    }
  }
`;

const CitizenScienceLanding = () => (
  <React.Fragment>
    <StyledDivWrapper>
      <StyledDivText>
        <p>
          Applications are now closed.
        </p>
      </StyledDivText>

      <StyledDivImg>
        <div style={{ marginTop: "50px" }}>
          <StyledImgVisual src={slikaKonkursa} />
        </div>
        <StyledBotttomDiv>
          <StyledImg style={{ width: "200px" }} src={logo} />
          <StyledImg style={{ width: "280px" }} src={logoM1} />
        </StyledBotttomDiv>
      </StyledDivImg>
    </StyledDivWrapper>
  </React.Fragment>
);

export default CitizenScienceLanding;
