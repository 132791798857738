import { css } from 'styled-components';

// xs je specijalan
const sizes = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

media.xs = (...args) => css`
  @media (max-width: 575.98px) {
    ${css(...args)};
  }
`;

export default media;
