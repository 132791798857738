import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import FA from 'helpers/FA';
import styled from 'styled-components';
// import logo from 'assets/images/cpnLogo.jpg';
// import logoM1 from "assets/images/logo-ministarstva2.jpg";

import Api from 'actions/api';

// const StyledContainer = styled(Container)`
//   display: flex;
//   /* flex-wrap: wrap; */
//   justify-content: center;
//   align-items: center;
//   > strong {
//     font-size: 2rem;
//     font-weight: normal;
//     line-height: 1.1;
//     > small {
//       display: block;
//       font-size: 1rem;
//       /* font-weight: bold; */
//       color: #999;
//     }
//   }
// `;

const StyledHeader = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
      
  @media only screen and (max-width: 486px) {
    justify-content: center;
    }
  }
`;

const StyledDiv = styled.div`
    display: flex;

  @media only screen and (max-width: 486px) {
    flex-direction: column;  
  }
`;

// const StyledImg = styled.img`
//   display: block;
//   height: 140px;
//   width: 280px;

//   @media only screen and (max-width: 486px) {
//     display: none;
//   }
// `;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  color: #666;
  border: 0;
  cursor: pointer;
  > svg {
    box-sizing: content-box;
    margin-left: 0.5rem;
    padding: 0.5rem;
    font-size: 1.25rem;
    color: var(--danger);
    background: #f2f2f2;
    border-radius: 50%;
    transition: ease-in all 0.2s;
  }
  &:hover > svg {
    color: #fff;
    background: var(--danger);
    transform: scale(1.125);
  }
`;

export default ({ admin, currentUser, logout, children }) => (
  <StyledHeader>
    <Container>
      <Link to="/">
        <StyledDiv>
        {/* <StyledImg style={{ margin: "auto 0" }} src={logo} />
        <StyledImg style={{ width: "200px", height: "170px" }} src={logoM1} /> */}
        </StyledDiv>
      </Link>
      {admin && 'Admin'}
      {currentUser &&
        currentUser.roles &&
        currentUser.roles.includes('admin') && (
          <StyledButton onClick={() => Api.getIzvestaj()}>
            <FA icon="s file-excel" />
          </StyledButton>
        )}
      {logout && (
        <StyledButton onClick={logout}>
          Logout
          <FA icon="s power-off" />
        </StyledButton>
      )}
    </Container>
  </StyledHeader>
);
