import { library } from '@fortawesome/fontawesome-svg-core';

// SOLID
import {
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faBuilding,
  //faCloudUpload,
  faEnvelope,
  faFileExcel,
  faExclamationTriangle,
  faMap,
  faPhone,
  //faSpinnerThird,
  faUserEdit,
  faUserPlus,
  faUserTimes,
  faUserTie,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons';

// REGULAR
import {
  faBan,
  faCheck,
  faFrown,
  faHandPointLeft,
  faHandPointRight,
  faMinusCircle,
  faPaperPlane,
  faPlus,
  faMinus,
  faPowerOff,
  faUndo,
  faUser,
  faUsers,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

// LIGHT
import {
  faClock,
  faCode,
  faFilePdf,
  faPaperclip,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBuilding,
  faCheck,
  faClock,
  //faCloudUpload,
  faCode,
  faEnvelope,
  faFileExcel,
  faExclamationTriangle,
  faFilePdf,
  faFrown,
  faHandPointLeft,
  faHandPointRight,
  faMap,
  faMinusCircle,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPhone,
  faPlus,
  faMinus,
  faPowerOff,
  //faSpinnerThird,
  faTimes,
  faUndo,
  faUser,
  faUserEdit,
  faUserPlus,
  faUsers,
  faUserTag,
  faUserTie,
  faUserTimes,
  faArrowDown
);
