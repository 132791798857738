import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import FA from 'helpers/FA';
import CheckboxGroup from 'components/CheckboxGroup';
import { renderField } from 'helpers/form';
import { required, maxLength } from 'helpers/validations';

const Form1 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>Подаци о пројекту</h2>
    <FormSection name="opstiPodaci">
      <FormGroup tag="fieldset">
        <Row>
          <Col md="6">
            <Field
              name="nazivProjekta"
              label="Назив пројекта"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="kljucneReci"
              label="Кључне речи пројекта"
              hint="Највише 4 речи одвојене запетом"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="pocetakSprovodjenjaProjekta"
              label="Почетак спровођења пројекта"
              type="date"
              min="2023-09-01"
              max="2024-12-30"
              validate={required}
              component={renderField}
            />
            <Field
              name="krajSprovodjenjaProjekta"
              label="Крај спровођења пројекта"
              type="date"
              min="2023-09-01"
              max="2024-12-30"
              validate={required}
              component={renderField}
            />
          </Col>
          <Col md="6">
            <CheckboxGroup
              name="naucneOblasti"
              label="Научне области које се промовишу пројектом"
              validate={required}
              options={[
                'природне науке',
                'техничке',
                'биотехничке',
                'медицинске',
                'друштвене',
                'хуманистичке',
                'мултидисциплинарне',
                'интердисциплинарне',
                'остало',
              ].map(o => ({ value: o, label: o }))}
            />
          </Col>
          <Col md="6">
            <Field
              name="apstraktProjekta"
              label="Апстракт пројекта"
              hint="До 1000 карактера"
              type="textarea"
              validate={[required, maxLength(1000)]}
              component={renderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="opisProjekta"
              label="Опис пројекта"
              hint="До 3000 карактера"
              type="textarea"
              validate={[required, maxLength(3000)]}
              component={renderField}
            />
          </Col>
          <Col md="6">
            <CheckboxGroup
              name="ciljnaGrupa"
              label="Циљна група"
              validate={required}
              options={[
                'основна школа',
                'средња школа',
                'студенти',
                'научна заједница',
                'шира јавност',
                'медији',
                'организације цивилног друштва',
                'локална самоуправа',
              ].map(o => ({ value: o, label: o }))}
            />
          </Col>
          <Col md="6">
            <Field
              name="ciljeviProjekta"
              label="Циљеви пројекта"
              hint="До 1500 карактера"
              type="textarea"
              validate={[required, maxLength(1500)]}
              component={renderField}
            />
          </Col>
          <Col xs="12">
            <CheckboxGroup
              name="ciljeviCPN"
              label={
                <React.Fragment>
                  У које се циљеве из Програма промоције науке,
                  научно-технолошких резултата и достигнућа пројекат уклапа?
                  Пажљиво прочитајте потциљеве у документу{' '}
                  <a
                    href="https://www.cpn.edu.rs/wp-content/uploads/2017/03/3predlogProgramaPromocijeNauke.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FA icon="s file-pdf" /> Предлог програма промоције науке,
                    научно-технолошких резултата и достигнућа
                  </a>
                  , а затим обележите један или више циљева.
                </React.Fragment>
              }
              validate={required}
              options={[
                'Развој капацитета истраживача за ширење научне културе',
                'Подстицај и развој комуникације између научноистраживачких организација са државном управом и јединицама територијалне управе и локалне самоуправе кроз размену вредности и пракси',
                'Развој различитих програма промоције науке заснованих на научној култури и научној писмености код свих грађана Републике Србије',
                'Јaчање ресурса за промоцију науке како би програми били доступнији грађанима',
              ].map(o => ({ value: o, label: o }))}
            />
          </Col>
          <Col md="6">
            <Field
              name="procenaBrojaPosetilaca"
              label="Процена броја посетилаца"
              hint="Унесите очекивани број"
              // type="number"
              validate={required}
              component={renderField}
            />
          </Col>
        </Row>
      </FormGroup>
    </FormSection>
    {formActions({ submitting })}
  </Form>
);

Form1.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form1);
