import React from 'react';
import Prijava from '../Prijava';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
import Form5 from './Form5';

export default () => (
  <Prijava
    naslov={
      <h1>
        <small>Јавни позив 2023 - Категорија 2</small>
        Пројекти промоције науке
      </h1>
    }
    konkurs="2023-kategorija-2"
    koraci={[
      'Подаци о пројекту',
      'Општи подаци',
      'Пројектни тим',
      'Партнери',
      'Имплементација',
    ]}
    forme={[Form1, Form2, Form3, Form4, Form5]}
  />
);
