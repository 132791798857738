import { SET_PRIJAVA } from '../constants/actionTypes';
import Api from './api';
// import { toUnderscore } from '../helpers/utilities';

export const loadPrijava = konkurs => async dispatch => {
  const prijava = await Api.getPrijava(konkurs);
  return dispatch({ type: SET_PRIJAVA, payload: prijava });
};

export const unloadPrijava = () => dispatch =>
  dispatch({ type: SET_PRIJAVA, payload: {} });

export const onSubmit = (json, konkurs, poslata = false) =>
  Api.patchPrijava({ json, konkurs, poslata });

export const getPrijavaZakacke = async (prijavaId, identifikator) => {
  const zakacke = await Api.getPrijavaZakacke(prijavaId, identifikator);
  const fileNames = zakacke.map(z => ({
    name: z.fajl_data.metadata.filename,
  }));
  return fileNames;
};

export const deletePrijavaZakacke = async (prijavaId, identifikator) => {
  const zakacke = await Api.deletePrijavaZakacke(prijavaId, identifikator);
  
  return zakacke;
};

export const postPrijavaZakacka = (prijavaId, identifikator, fajl) =>
  Api.postPrijavaZakacka({
    zakacka: { prijava_id: prijavaId, identifikator, fajl },
  });
