import React from 'react';
import Prijava from '../Prijava';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';

export default () => (
  <Prijava
    naslov={
      <h1>
        <small>Јавни позив 2021 - Категорија 3</small>
        Идејна решења за интерактивне експонате у парковима науке
      </h1>
    }
    konkurs="2021-kategorija-3"
    koraci={['Општи подаци', 'Пројектни тим', 'Имплементација']}
    forme={[Form1, Form2, Form3]}
  />
);
