import styled from 'styled-components';
import { Button } from 'reactstrap';

export default styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: ease-out all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;
