import React from 'react';
import PropTypes from 'prop-types';
import { FormSection, Field, reduxForm } from 'redux-form';
import { Row, Col, Form, FormGroup } from 'reactstrap';
// import CheckboxGroup from 'components/CheckboxGroup';
import { renderField } from 'helpers/form';
import { required, maxLength, totalLength, email, number } from 'helpers/validations';

const Form2 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>
      <i className="fa fa-info-circle" />
      Општи подаци
    </h2>
    <FormSection name="odgovornoLice">
      <FormGroup tag="fieldset">
        <h3>
          Одговорно лице{' '}
          <small>
            (координатор пројекта, односно лице задужено за контакт)
          </small>
        </h3>
        <Row>
          <Col md="6">
            <Field
              name="imePrezime"
              label="Име и презиме"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="zanimanje"
              label="Занимање"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="telefon"
              label="Мобилни телефон"
              type="text"
              validate={[required, number]}
              component={renderField}
            />
            <Field
              name="email"
              label="Електронска пошта"
              type="email"
              validate={[required, email]}
              component={renderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="biografija"
              label="Стручна биографија"
              hint="До 2000 карактера"
              type="textarea"
              validate={[required, maxLength(2000)]}
              component={renderField}
            />
          </Col>
        </Row>
      </FormGroup>
    </FormSection>
    <FormSection name="institucija">
      <FormGroup tag="fieldset">
        <h3>Подаци о институцији</h3>
        <Row>
          <Col md="6">
            <Field
              name="naziv"
              label="Назив институције"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="sediste"
              label="Седиште"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="pib"
              label="ПИБ"
              type="number"
              validate={[required, totalLength(9)]}
              component={renderField}
            />
            <Field
              name="maticniBroj"
              label="Матични број организације"
              type="number"
              validate={[required, totalLength(8)]}
              component={renderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="odgovornoLice"
              label="Одговорно лице"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="vebSajt"
              label="Веб сајт"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="email"
              label="Имејл"
              type="email"
              validate={[required, email]}
              component={renderField}
            />
          </Col>
        </Row>
      </FormGroup>
    </FormSection>
    {formActions({ submitting })}
  </Form>
);

Form2.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form2);
