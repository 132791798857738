import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Form } from 'reactstrap';
import { required, maxLength, maxFileCount } from 'helpers/validations';
import renderZakackaField from '../renderZakackeField';
import { renderField } from 'helpers/form';

const Form3 = ({ formActions, handleSubmit, submitting }) => (
  
  <Form onSubmit={handleSubmit}>
    <h2>
      <i className="fa fa-info-circle" />
      Implementation and Budget
    </h2>
    <hr />
    <Row>
      <Col>
      <Field
              name="implementation"
              label="Implementation *"
              descTitle="Answer the following questions:"
              desc="Can the project goals be achieved on the scale proposed, with the resources and team proposed?
              Are the requested resources relevant and suitable for the proposal?
              Are any critical activities or requirements (such as dissemination) not accounted for within the budget?"
              type="textarea"
              maxLen="2000"
              validate={[required, maxLength(2000)]}
              component={renderField}
            />
      </Col>
      <Col>
      <Field
              name="scienceApproach"
              label="Open science approach *"
              descTitle="Answer the following questions:"
              desc="Does the project follow open science principles?
              Which data or other outputs do you intend to gather or produce? 
              Will project data and outputs be openly available?
              Do the approach account for data protection and any personal or sensitive data?
              What (if any) other ethical challenges does your project pose?"
              type="textarea"
              maxLen="2000"
              validate={[required, maxLength(2000)]}
              component={renderField}
            />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name="budget"
          label="Budget"
          accept=".xlsx"
          placeholderText ="Upload file (Excel files only)"
          validate={[required, maxFileCount(1)]}
          component={renderZakackaField}
        />
      </Col>
      <Col>
        <Field
          name="workPlan"
          label="Work plan"
          hint="Briefly explain the project timeline together with the main activities"
          accept=".pdf, .xlsx, .docx"
          placeholderText ="Upload file (Excel, pdf, Word)"
          validate={[required, maxFileCount(1)]}
          component={renderZakackaField}
        />
      </Col>
    </Row>
    {formActions({ submitting })}
  </Form>
);

Form3.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form3);
