import React from 'react';
import Loader from 'components/Loader';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    state = { component: null };

    async componentDidMount() {
      const { default: component } = await importComponent();
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ component });
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
}
