import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
  arrayPush,
} from 'redux-form';
import { Form } from 'reactstrap';
import { required } from 'helpers/validations';
import renderZakackaField from '../renderZakackeField';
import renderTroskoviPoAktivnostima from '../renderTroskoviPoAktivnostima';

class renderOdlukeUnconnected extends React.Component {
  // componentDidMount + componentDidUpdate
  // u prvom renderu fields su prazni
  static getDerivedStateFromProps(nextProps) {
    const { partneri, fields } = nextProps;
    if (fields.length > partneri.length){
      fields.removeAll();
    }
    if (((fields.length === 0 && partneri) || (partneri.length > fields.length))){
      //for (let i = 0; i < partneri.length ; i += 1) {
        nextProps.pushToFields({});
      //}
    }
    return null;
  }

  state = {};

  render() {
    const { partneri, fields } = this.props;
    return (
      <React.Fragment>
        {fields.map((fieldName, index) => (
          <React.Fragment key={`${fieldName}-${index}`}>
            <h3 style={{ marginBottom: '.5rem', paddingBottom: '.5rem' }}>
              {partneri[index]}
            </h3>
            <Field
              name={`${fieldName}.odluka`}
              label="Одлука о учешћу"
              hint="Поље je обавезно, само PDF фајлови"
              accept=".pdf"
              validate={required}
              component={renderZakackaField}
            />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}

const renderOdluke = connect(
  state => {
    const institucija = formValueSelector('prijava')(state, 'institucija');
    const partneri = [institucija.naziv];
    const institucije = formValueSelector('prijava')(state, 'partnerskeInstitucije') || [];
    const uSaradnji = formValueSelector('prijava')(state, 'uSaradjniSaDrugimOrganizacijama');
    if (uSaradnji === 'false' || uSaradnji === undefined) {
      return {partneri}
    }
    return { partneri: partneri.concat (institucije.map(n => n.naziv)) };
  },
  dispatch => ({
    pushToFields(val) {
      dispatch(arrayPush('prijava', 'odlukaOUcescu', val));
      },
  })
)(renderOdlukeUnconnected);

const Form5 = ({ formActions, handleSubmit, submitting, uSaradjniSaDrugimOrganizacijama }) => (
  <Form onSubmit={handleSubmit}>
    <h2>
      <i className="fa fa-info-circle" />
      Имплементација пројекта
    </h2>
    <FieldArray name="planRada" component={renderTroskoviPoAktivnostima} />
    <hr />
    <FieldArray name="odlukaOUcescu" component={renderOdluke} />
    <hr />
    <Field
      name="dodatniDokument"
      label="Додатни документ"
      accept=".pdf"
      hint="Само PDF фајлови"
      // validate={required}
      component={renderZakackaField}
    />
    {formActions({ submitting })}
  </Form>
);

Form5.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form5);
