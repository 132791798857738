// import React from 'react';
import styled from 'styled-components';
import media from 'helpers/mediaQueries';

export default styled.div`
  margin: 2rem 0 4rem 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  /* ${media.xs`
    margin: 0 -15px;
    padding:  1rem;
    width: calc(100% + 30px);
  `}; */
  ${media.md`
    padding: 2rem;
  `};
`;
