import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import FA from 'helpers/FA';
import { renderField } from 'helpers/form';
import { required, maxLength, email } from 'helpers/validations';

export default class renderClanovi extends React.Component {
  componentDidMount = () => {
    const { fields } = this.props;
    if (fields.length === 0) fields.push({});
  };
  render() {
    const { fields, meta: { error } } = this.props;
    return (
      <React.Fragment>
        {fields.map((clan, index) => (
          <FormGroup tag="fieldset" key={clan}>
            <h3>
              <FA icon="s user" /> Члан #{index + 1}
            </h3>
            <Row>
              <Col md="6">
                <Field
                  name={`${clan}.imePrezime`}
                  label="Име и презиме"
                  type="text"
                  validate={required}
                  component={renderField}
                />
                <Field
                  name={`${clan}.zanimanje`}
                  label="Занимање"
                  type="text"
                  validate={required}
                  component={renderField}
                />
                <Field
                  name={`${clan}.email`}
                  label="Имејл"
                  type="text"
                  validate={[required, email]}
                  component={renderField}
                />
              </Col>
              <Col md="6">
                <Field
                  name={`${clan}.biografija`}
                  label="Стручна биографија (макс. 2000 карактера)"
                  type="textarea"
                  rows="10"
                  validate={[required, maxLength(2000)]}
                  component={renderField}
                />
              </Col>
            </Row>
            {fields.length > 1 && (
              <Button
                color="danger"
                size="sm"
                onClick={() => fields.remove(index)}
              >
                <FA icon="s user-times" /> Уклони члана
              </Button>
            )}
          </FormGroup>
        ))}
        <hr />
        <Button color="success" size="sm" onClick={() => fields.push({})}>
          <FA icon="s user-plus" /> Додај новог члана
        </Button>
        {error && error}
      </React.Fragment>
    );
  }
}
