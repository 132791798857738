import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import FA from 'helpers/FA';
import { renderField } from 'helpers/form';
import { required, maxLength, email } from 'helpers/validations';

export default class renderMembers extends React.Component {
  componentDidMount = () => {
    const { fields } = this.props;
    if (fields.length === 0) fields.push({});
  };


  render() {
    const { fields, meta: { error }, color } = this.props;

  const h3Style = {
      background: color, 
    };

    return (
      <React.Fragment>
        {fields.map((clan, index) => (
          <FormGroup tag="fieldset" key={clan}>
            <h3 style={h3Style}>
              <FA icon="s user" /> Member #{index + 1}
            </h3>
            <Row>
              <Col md="6">
                <Field
                  name={`${clan}.name`}
                  label="Name *"
                  type="text"
                  validate={required}
                  component={renderField}
                />
                <Field
                  name={`${clan}.affiliation`}
                  label="Affiliation"
                  type="text"
                  component={renderField}
                />
                <Field
                  name={`${clan}.email`}
                  label="Email *"
                  type="text"
                  validate={[required, email]}
                  component={renderField}
                />
              </Col>
              <Col md="6">
                <Field
                  name={`${clan}.shortBio`}
                  label="Short bio *"
                  type="textarea"
                  rows="10"
                  maxLen="1500"
                  validate={[required, maxLength(1500)]}
                  component={renderField}
                />
              </Col>
            </Row>
            {fields.length > 1 && (
              <Button
                color="danger"
                size="sm"
                onClick={() => fields.remove(index)}
              >
                <FA icon="s user-times" /> Remove member
              </Button>
            )}
                          <hr />

          </FormGroup>
        ))}
        <Button color="success" size="sm" onClick={() => fields.push({})}>
          <FA icon="s user-plus" /> Add member
        </Button>
        {error && error}
      </React.Fragment>
    );
  }
}
