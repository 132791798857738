import React from 'react';
import Prijava from '../Prijava';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
import Form5 from './Form5';

export default () => (
  <Prijava
    naslov={
      <h1>
        Public Call for funding citizen science projects
      </h1>
    }
    konkurs="citizen-science"
    koraci={[
      'Project idea and team',
      'Impact and Community ',
      'Implementation and Budget',
      'Project coordinator',
      'Partners',
    ]}
    forme={[Form1, Form2, Form3, Form4, Form5]}
  />
);
