// import React from 'react';

// const ConfirmComponent = () => {
//     return (<div><h1>Ваш налог је потврђен</h1>
//     <h3><a href="/auth/login">Наставите на сајт</a> </h3>
//     </div>);
// }
// export default ConfirmComponent;
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {logout} from 'react-devise';
import url from 'url';
import Api from '../actions/api'

class ConfirmComponent extends Component {
  state = {
    confirming: true
  };
  confirm = async (e)  => {
    const response = await getUserConfirmation(this.props.location.search);
  }
  componentDidMount() {
    const {query} = url.parse(this.props.location.search, true);
    
    return this.confirm().then(response => {
        let newState = {
            confirming: false
        };
        this.setState(newState);
    });
    // return this.props.doConfirm(query.confirmation_token).then(response => {
    //   let newState = {
    //     confirming: false
    //   };
    //   console.log(this.props)
    //   if (response.ok) {
    //     this.setState(newState);
    //   } else if (response.status === 422) {
    //     response.json().then(errors => {
    //       const errorMessages = Object.keys(errors).reduce((result, field) => {
    //         result.push(errors[field].map(predicate => `${field} ${predicate}`));
    //         return result;
    //       }, []);
    //       this.setState({
    //         ...newState,
    //         errors: errorMessages
    //       });
    //     });
    //   }
    // });
  }
  render() {
    if (this.state.confirming) {
      return <div>Confirming...</div>;
    }
    const {auth: {messages: {confirmSucceeded: confirmSucceededMessage, confirmContinueLinkText, confirmFailed: confirmFailedMessage}, viewPlugin: {View, Heading, FormError}}} = this.props;
    if (this.state.errors) {
      const {auth: {AuthLinks}} = this.props;
      return (
        <div>
          <FormError>
            <p>{confirmFailedMessage}</p>
            <ul>
              {this.state.errors.map(error => <li key={error}>{error}</li>)}
            </ul>
          </FormError>
          <AuthLinks />
        </div>
      );
    }
    return (
      <View>
        <Heading>
          Confirmed
        </Heading>
        <p>{confirmSucceededMessage}</p>
        <Link to="/auth/login" >{confirmContinueLinkText}</Link>
      </View>
    );
  }
}
const getUserConfirmation = async (token) => {
    const confirm = await Api.getUserConfirmation(token);
    return confirm;
  };
const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // doConfirm: token => confirm(token, dispatch),
    doLogout: () => logout(dispatch)
  };
};
const ConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmComponent);
// const ROUTES = {
//     confirm: {
//         method: 'GET',
//         path: 'confirmation'
//       },
//     }
// const confirm = token => {
//     return fetch(ROUTES.confirm, {
//       confirmation_token: token // eslint-disable-line camelcase
//     });
//   };

export {
    ConfirmComponent,
  ConfirmContainer as default
};