import React from 'react';
import { Field } from 'redux-form';
import FA from 'helpers/FA';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { renderField } from 'helpers/form';
import { required } from 'helpers/validations';
import OkrugliButton from 'components/styled/OkrugliButton';
import AktivnostiTable from './AktivnostiTable';

const StyledAktivnostiList = styled.ol`
  padding: 0;
  list-style: none;
  > li {
    position: relative;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    > button {
      position: absolute;
      top: -12px;
      right: -12px;
    }
  }
  > li + li {
    margin-top: 0.5rem;
  }
`;

const iznosAktivnosti = ({
  ljudskiResursi,
  putovanjeISmestaj,
  oprema,
  materijal,
  ostali,
}) => {
  const zbir =
    Number(ljudskiResursi) +
    Number(putovanjeISmestaj) +
    Number(oprema) +
    Number(materijal) +
    Number(ostali);
  return !Number.isNaN(zbir) && zbir;
};

const ukupanIznosAktivnosti = aktivnosti => {
  if (!aktivnosti) return null;
  return aktivnosti.reduce(
    (zbir, aktivnost) => zbir + iznosAktivnosti(aktivnost),
    0
  );
};

export default ({ fields, meta: { error, submitFailed } }) => {
  if (fields.length === 0) fields.push({});
  return (
    <React.Fragment>
      <h4>Финансијски предрачун трошкова по активностима</h4>
      <StyledAktivnostiList>
        {fields.map((fieldName, index) => (
          <li key={`${fieldName}-${index}`}>
            <OkrugliButton
              color="danger"
              size="sm"
              onClick={() => {
                fields.remove(index);
              }}
            >
              <FA icon="s times" />
            </OkrugliButton>
            <AktivnostiTable>
              <thead>
                <tr>
                  <th width="61.8%">
                    <h5 style={{ margin: 0 }}>Активност</h5>
                  </th>
                  <th>Од</th>
                  <th>До</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Field
                      name={`${fieldName}.aktivnost`}
                      label={null}
                      validate={required}
                      component={renderField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${fieldName}.od`}
                      label={null}
                      type="date"
                      min="2023-09-01"
                      max="2024-12-30"
                      validate={required}
                      component={renderField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${fieldName}.do`}
                      label={null}
                      type="date"
                      min="2023-09-01"
                      max="2024-12-30"
                      validate={required}
                      component={renderField}
                    />
                  </td>
                </tr>
              </tbody>
            </AktivnostiTable>
            <AktivnostiTable>
              <thead>
                <tr>
                  <th colSpan="7">
                    <h5 style={{ margin: '0.5rem 0 0 0' }}>Трошкови</h5>
                  </th>
                </tr>
                <tr>
                  <th>људски ресурси</th>
                  <th>путовање и смештај</th>
                  <th>опрема</th>
                  <th>материјал</th>
                  <th>остали</th>
                  <th>збир</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Field
                      name={`${fieldName}.ljudskiResursi`}
                      label={null}
                      type="number"
                      validate={required}
                      component={renderField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${fieldName}.putovanjeISmestaj`}
                      label={null}
                      type="number"
                      validate={required}
                      component={renderField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${fieldName}.oprema`}
                      label={null}
                      type="number"
                      validate={required}
                      component={renderField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${fieldName}.materijal`}
                      label={null}
                      type="number"
                      validate={required}
                      component={renderField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${fieldName}.ostali`}
                      label={null}
                      type="number"
                      validate={required}
                      component={renderField}
                    />
                  </td>
                  <td className="ukupno">
                    <Input
                      type="number"
                      disabled
                      value={iznosAktivnosti(fields.get(index))}
                    />
                  </td>
                </tr>
              </tbody>
            </AktivnostiTable>
          </li>
        ))}
      </StyledAktivnostiList>
      <OkrugliButton color="success" size="sm" onClick={() => fields.push({})}>
        <FA icon="s plus" />
      </OkrugliButton>
      <strong style={{ float: 'right' }}>
        Укупно: {ukupanIznosAktivnosti(fields.getAll())}
      </strong>
    </React.Fragment>
  );
};
