import React from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'reactstrap';
import { required } from 'helpers/validations';
import renderZakackaField from '../renderZakackeField';

const Form4 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>Документација</h2>
    <Field
      name="reference"
      label="Референце извођача и/или чланова тима"
      hint="Поље није обавезно, само PDF фајлови"
      accept=".pdf"
      // validate={required}
      component={renderZakackaField}
    />
    <Field
      name="saglasnost"
      label="Сагласност пријавиоца да је упознат са техничким спецификацијама експоната и да пријављивањем прихвата да експонат(е) изради у складу са њима"
      hint="Поље је обавезно, само PDF фајлови"
      accept=".pdf"
      validate={required}
      component={renderZakackaField}
    />
    <Field
      name="dodatniDokumenti"
      label="Додатни документи"
      hint="Поље није обавезно, само PDF фајлови"
      accept=".pdf"
      component={renderZakackaField}
    />

    {formActions({ submitting })}
  </Form>
);
// Form4 = connect(state => ({ prijava: state.prijava }))(Form4);

Form4.propTypes = {
  // prijava: PropTypes.shape({}),
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

// Form4.defaultProps = {
//   prijava: {},
// };

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form4);
