import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Alert, Button, Form, FormGroup } from 'reactstrap';
import FA from 'helpers/FA';
import { renderField } from 'helpers/form';
import { required } from 'helpers/validations';
import renderTroskoviPoAktivnostima from '../renderTroskoviPoAktivnostima';

const renderNaucniKlubovi = ({ fields, meta: { error } }) => {
  if (fields.length === 0){
    fields.push({});
    fields.push({});
  }
  return (
    <React.Fragment>
      <Alert color="info">
        Молимо изаберите обавезно два научна клуба који ће учествовати у
        реализацији пројекта
      </Alert>
      {fields.map((fieldName, index) => (
        <FormGroup tag="fieldset" key={fieldName}>
          <h3 style={{ marginBottom: '.5rem' }}>Научни клуб #{index + 1}</h3>
          <Field
            name={`${fieldName}.nazivNaucnogKluba`}
            label="Назив научног клуба"
            type="select"
            options={[
              'НК Кикинда',
              'НК Шабац',
              'НК Смедерево',
              'НК Рановац',
              'НК Крагујевац',
              'НК Крушевац',
              'НК Ниш',
              'НК Лесковац',
              'НК Чачак',
              'НК Горњи Милановац',
              'НК Зајечар',
              'НК Нови Сад',
            ].map(v => ({ value: v, label: v }))}
            validate={required}
            component={renderField}
          />
          <FieldArray
            name={`${fieldName}.planRada`}
            component={renderTroskoviPoAktivnostima}
          />
          {/* {fields.length > 1 && (
            <React.Fragment>
              <hr />
              <Button
                color="danger"
                size="sm"
                onClick={() => fields.remove(index)}
              >
                <FA icon="r times" /> Уклони научни клуб #{index + 1}
              </Button>
            </React.Fragment>
          )} */}
        </FormGroup>
      ))}
      {/* {fields.length < 2 && (
        <React.Fragment>
          <hr />
          <Button color="success" onClick={() => fields.push({})}>
            <FA icon="r plus" /> Додај још један научни клуб
          </Button>
        </React.Fragment>
      )} */}
    </React.Fragment>
  );
};

const Form3 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>Имплементација пројекта</h2>
    <FieldArray
      name="aktivnostiNaucnihKlubova"
      component={renderNaucniKlubovi}
    />
    {formActions({ submitting })}
  </Form>
);

Form3.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form3);
