import React from 'react';
import PropTypes from 'prop-types';
import { FormSection, Field, reduxForm } from 'redux-form';
import { Row, Col, Form, FormGroup } from 'reactstrap';
// import CheckboxGroup from 'components/CheckboxGroup';
import { renderField } from 'helpers/form';
import { required, maxLength } from 'helpers/validations';
import CheckboxGroup from 'components/CheckboxGroup';

const Form2 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>
      <i className="fa fa-info-circle" />
      Impact and Community 
    </h2>
    <hr />
    <FormSection name="impactCommunity">
      <FormGroup tag="fieldset">
        <Row>
          <Col md="6">
            <Field
              name="impact"
              label="Impact of the project *"
              descTitle="Answer the following questions:"
              desc="What challenges will the citizen science approach allow you to address that you could not otherwise achieve?
              What will be the primary responsibilities of the involved citizens? 
              Which territories will your project cover? 
              What are the end benefits of your project?
              What positive changes do you expect for your stakeholders by the end of the project? Please mention specific stakeholder groups (researchers, citizens, decision-makers, etc.)."
              type="textarea"
              maxLen="2000"
              validate={[required, maxLength(2000)]}
              component={renderField}
            />
            <CheckboxGroup
              name="projectContribution"
              label="Which SDG will your project contribute to?"
              validate={maxLength(3)}
              options={[
                'No poverty ',
                'Zero hunger ',
                'Good Health',
                'Quality education',
                'Gender equality',
                'Clean water',
                'Clean energy',
                'Decent work',
                'Industry, innovation, infrastructure ',
                'Reduced inequalities ',
                'Sustainable cities ',
                'Responsible consumption ',
                'Climate action',
                'Life below water',
                'Life on land',
                'Peace and justice',
                'Partnerships for the goals ',
              ].map(o => ({ value: 0, label: o }))}
            />

          </Col>
          <Col md="6">
            <Field
              name="benefit"
              label="Benefit for the community *"
              descTitle="Answer the following questions:"
              desc="Which activities in your proposed project will involve citizens and/or the public?
              Are the planned activities achievable and accessible to citizens?
              Do they have sufficient capacity to conduct an engagement at the proposed scale?
              Is there an effective strategy to engage existing or new citizens in the project activities?
              How do you intend to attract and maintain citizen and stakeholder engagement? Why will people want to contribute?
              Will there be an opportunity for involved actors and the public to learn something from the project outcomes or activities? "
              type="textarea"
              maxLen="2000"
              validate={[required, maxLength(2000)]}
              component={renderField}
            />
            <Field
              name="alignment"
              label="Alignment with SDGs *"
              descTitle="Answer the following questions:"
              desc="Is your project in line with the SDGs? 
              If yes, in what way? 
              If not, what crucial societal challenge will your project address?"
              type="textarea"
              maxLen="1000"
              validate={[required, maxLength(1000)]}
              component={renderField}
            />
              <p style={{color: 'green', marginTop:'50px'}}>More about SDGs: <br></br>
                <a style={{color: 'green'}} target='_blank' href='https://www.undp.org/sustainable-development-goals'>https://www.undp.org/sustainable-development-goals</a>
              </p>
          </Col>
        </Row>
      </FormGroup>
    </FormSection>
    {formActions({ submitting })}
  </Form>
);

Form2.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form2);
