import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, FormSection, Field, reduxForm } from 'redux-form';
import { Row, Col, Form, FormGroup } from 'reactstrap';
// import CheckboxGroup from 'components/CheckboxGroup';
import { renderField } from 'helpers/form';
import {
  required,
  maxLength,
  email,
  totalLength,
  isValidUrl,
  maxFileCount,
  phoneNumber,
  number,
} from 'helpers/validations';
import renderZakackaField from '../renderZakackeField';
import renderMembers from '../renderMembers';

const Form4 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>
      <i className="fa fa-info-circle" />
      Project Coordinator
    </h2>
    <hr />
    <FormSection name="applicant">
      <FormGroup tag="fieldset">
        <h3 style={{backgroundColor:'rgb(224, 236, 212)'}}>Applicant / Project coordinator</h3>
        <Row>
          <Col md="6">
            <Field
              name="nameApplicant"
              label="Name *"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="phoneApplicant"
              label="Phone *"
              type="text"
              validate={[required, phoneNumber]}
              component={renderField}
            />
            <Field
              name="emailApplicant"
              label="Email *"
              type="email"
              validate={[required, email]}
              component={renderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="shortBioApplicant"
              label="Short bio *"
              type="textarea"
              maxLen="1500"
              validate={[required, maxLength(1500)]}
              component={renderField}
            />
          </Col>
        </Row>
        <h3 style={{backgroundColor:'#ffc107'}}>Institution / Organisation</h3>
        <Row>
          <Col md="4">
            <Field
              name="nameInstitution"
              label="Name *"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="cityInstitution"
              label="City / place *"
              type="text"
              validate={required}
              component={renderField}Website
            />
            <Field
              name="address"
              label="Address"
              type="text"
              component={renderField}
            />
          </Col>
          <Col md="4">
            <Field
              name="responsiblePersonInstitution"
              label="Responsible person *"
              type="text"
              validate={required}
              component={renderField}
            />
            <Field
              name="pibInstitution"
              label="PIB"
              validate={[totalLength(9), number]}
              component={renderField}
            />
            <Field
              name="websiteInstitution"
              label="Website *"
              type="text"
              validate={[isValidUrl, required]}
              component={renderField}
            />
          </Col>
          <Col md="4">
            <Field
              name="annexes"
              label="Annexes"
              hint="(for example, letters of intentions, agreements on cooperation, CVs, publications lists, etc.)"
              accept=".pdf, .docx"
              placeholderText="Upload file (pdf, Word)"
              validate={maxFileCount(10)}
              component={renderZakackaField}
            />
          </Col>
        </Row>
        <FieldArray name="members" color='rgb(224, 236, 212)' component={renderMembers} />
      </FormGroup>
    </FormSection>
    {formActions({ submitting })}
  </Form>
);

Form4.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: "prijava", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form4);
