import { SET_PRIJAVE, UPDATE_PRIJAVA } from '../../constants/actionTypes';

const defaultState = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_PRIJAVE:
      return {
        ...state,
        byId: action.payload.reduce((acc, item) => {
          acc[item.id] = { ...item };
          return acc;
        }, {}),
      };
    case UPDATE_PRIJAVA:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    default:
      return state;
  }
};
