import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import createBrowserHistory from 'history/createBrowserHistory';
import { reducer as formReducer } from 'redux-form';
import { initReactDevise } from 'react-devise';
import reactDeviseReducers from 'react-devise/lib/reducers';
import 'initializers/fontAwesome';
import { API_HOST } from './constants/config';
import common from './reducers/common';
import prijava from './reducers/prijava';
// admin
import prijave from './reducers/admin/prijave';
import ocenjivaci from './reducers/admin/ocenjivaci';
import ConfirmComponent from './components/ConfirmComponent';

const getReactDeviseConfig = initReactDevise({
    clientResourceName: 'auth',
    // apiResourceName: 'api/auth',
    apiHost: API_HOST,
    // defaultViewPluginSettings: myDefaultViewPluginSettings,
    // viewPlugins: myPlugins,
    routes: {
        login: { linkText: 'Login' },
        signup: { linkText: 'Register' },
        requestReconfirm: { linkText: 'You did not receive an account confirmation link?' },
        requestResetPassword: { linkText: 'Forget password?' },
        confirm: {
            path: '/confirmation',
            component: ConfirmComponent
        },
    },
    messages: {
        loginFailed: 'Login failed',
        reqeustReconfirmSucceeded: 'You should soon receive an email with a link to confirm your account.',
        requestResetPasswordSucceeded: 'You should soon receive an email with a link to reset your password.',
        mustLoginMessage: 'Sign in to continue',
        confirmSucceeded: 'Your account has been confirmed',
        confirmContinueLinkText: 'Continue to the site',
        confirmFailed: 'There is a problem confirming your account',
        signUpSucceeded: 'You should soon receive an email with a link to confirm your account. Click on the link in the email.',
        updateSucceeded: 'Your account has been successfully updated',
        updateNeedsConfirmation: 'You have successfully updated your account, but you need to confirm your new email address. Check your email and click on the link to confirm the new address.',
    },
});

const history = createBrowserHistory();

const reducer = combineReducers({
    ...reactDeviseReducers,
    router: routerReducer,
    form: formReducer,
    common,
    prijava,
    // admin
    prijave,
    ocenjivaci,
});

/* eslint-disable-next-line no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancer(applyMiddleware(thunkMiddleware, routerMiddleware(history)))
);

export { history, store, getReactDeviseConfig };