import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Button, Form, FormGroup, Row, Col } from 'reactstrap';
import FA from 'helpers/FA';
import { renderField } from 'helpers/form';
import { required } from 'helpers/validations';
// import SingleDatePicker from "components/SingleDatePicker";
import renderPredmer from '../renderPredmer';

const renderEksponati = ({ fields, meta: { error } }) => {
  if (fields.length === 0) fields.push({});
  return (
    <React.Fragment>
      {fields.map((fieldName, index) => (
        <FormGroup tag="fieldset" key={fieldName}>
          <h3 style={{ marginBottom: '.5rem' }}>
            Експонат који се реализује #{index + 1}
          </h3>
          <Field
            name={`${fieldName}.naziv`}
            label="Назив-место експоната"
            type="select-group"
            options={[
              {
                label: 'Горњи Милановац',
                items: [
                  { value: 'Интерактивни пиксел зид - Горњи Милановац', label: 'Интерактивни пиксел зид' },
                  { value: 'Планетаријум - Горњи Милановац', label: 'Планетаријум' },
                  { value: 'НУМ магнетини сто - Горњи Милановац', label: 'НУМ магнетини сто' },
                ],
              },
              {
                label: 'Кикинда',
                items: [
                  { value: 'Планетаријум - Кикинда', label: 'Планетаријум' },
                  { value: 'Ботаничка слагалица - Кикинда', label: 'Ботаничка слагалица' },
                  { value: 'Галтонова кутија - Кикинда', label: 'Галтонова кутија' },
                  { value: 'Ремен систем - Кикинда', label: 'Ремен систем' },
                  { value: 'Човек систем - скелетни систем - Кикинда', label: 'Човек систем - скелетни систем' },
                  { value: 'Планисфера - Кикинда', label: 'Планисфера' },
                ],
              },
              {
                label: 'Књажевац',
                items: [
                  { value: 'Периодни систем елемената - Књажевац', label: 'Периодни систем елемената' },
                  { value: 'Планетаријум - Књажевац', label: 'Планетаријум' },
                ],
              },
              {
                label: 'Крагујевац',
                items: [
                  { value: 'Галтонова кутија - Крагујевац', label: 'Галтонова кутија' },
                  { value: 'Планетаријум - Крагујевац', label: 'Планетаријум' },
                  { value: 'Оптичке илузије  - Да ли су све сиве тачке исте нијансе? - Крагујевац', label: 'Оптичке илузије  - Да ли су све сиве тачке исте нијансе?' },
                  { value: 'Оптичке илузије - 2Д/3Д - Крагујевац', label: 'Оптичке илузије - 2Д/3Д' },
                ],
              },
              {
                label: 'Крушевац',
                items: [
                  { value: 'Шапталица - Крушевац', label: 'Шапталица',},
                  { value: 'Ремен систем - Крушевац', label: 'Ремен систем' },
                  { value: 'Ботаничка слагалица - Крушевац', label: 'Ботаничка слагалица' },
                  { value: 'НУМ магнетини сто - Крушевац', label: 'НУМ магнетини сто' },
                ],
              },
              {
                label: 'Лесковац',
                items: [
                  { value: 'Интерактивни пиксел зид - Лесковац', label: 'Интерактивни пиксел зид' },
                  { value: 'Човек систем - скелетни систем - Лесковац', label: 'Човек систем - скелетни систем' },
                  { value: 'Илузија столице - Лесковац', label: 'Илузија столице' },
                ],
              },
              {
                label: 'Ниш',
                items: [
                  { value: 'Човек систем - скелетни систем - Ниш', label: 'Човек систем - скелетни систем' },
                  { value: 'Човек систем - кардиоваскуларни систем - Ниш', label: 'Човек систем - кардиоваскуларни систем'},
                  { value: 'Планетаријум - Ниш', label: 'Планетаријум'},
                  { value: 'Интерактивни пиксел зид - Ниш', label: 'Интерактивни пиксел зид' },
                ],
              },
              {
                label: 'Рановац',
                items: [
                  { value: ' Човек систем - кардиоваскуларни систем - Рановац', label: 'Човек систем - кардиоваскуларни систем'},
                  { value: 'Месечев сат - Рановац', label: 'Месечев сат' },
                ],
              },
              {
                label: 'Смедерево',
                items: [
                  { value: 'Шапталица - Смедерево', label: 'Шапталица',},
                  { value: 'Планетаријум - Смедерево', label: 'Планетаријум'},
                  { value: 'Галтонова кутија - Смедерево', label: 'Галтонова кутија' },
                  { value: 'Звучна огледала - Смедерево', label: 'Звучна огледала' },
                  { value: 'Стуб даљинар - Смедерево', label: 'Стуб даљинар' },
                ],
              },
              {
                label: 'Ужице',
                items: [
                  { value: 'Планетаријум - Ужице', label: 'Планетаријум'},
                  { value: 'Стуб даљинар - Ужице', label: 'Стуб даљинар' },
                  { value: 'НУМ магнетини сто - Ужице', label: 'НУМ магнетини сто' },
                  { value: 'Ендемичне и угрожене биљне врсте Србије - Ужице', label: 'Ендемичне и угрожене биљне врсте Србије' },
                ],
              },
              {
                label: 'Шабац',
                items: [
                  { value: 'Ботаничка слагалица - Шабац', label: 'Ботаничка слагалица' },
                  { value: 'Sighbot - Шабац', label: 'Sighbot' },
                  { value: 'НУМ магнетини сто - Шабац', label: 'НУМ магнетини сто' },
                  { value: 'Интерактивни пиксел зид - Шабац', label: 'Интерактивни пиксел зид' },
                  { value: 'Kохезија-Адхезија - Шабац', label: 'Kохезија-Адхезија' },
                ],
              },
            ]}
            validate={required}
            component={renderField}
          />
          <FieldArray name={`${fieldName}.predmer`} component={renderPredmer} />
          <hr />
          <Row>
            <Col>
              <Field
                name={`${fieldName}.pocetakSprovodjenjaProjekta`}
                label="Почетак спровођења пројекта"
                type="date"
                min="2019-12-01"
                max="2020-12-30"
                validate={required}
                component={renderField}
              />
            </Col>
            <Col>
              <Field
                name={`${fieldName}.krajSprovodjenjaProjekta`}
                label="Крај спровођења пројекта"
                type="date"
                min="2019-12-01"
                max="2020-12-30"
                validate={required}
                component={renderField}
              />
            </Col>
          </Row>
          {fields.length > 1 && (
            <React.Fragment>
              <hr />
              <Button
                color="danger"
                size="sm"
                onClick={() => fields.remove(index)}
              >
                <FA icon="s times" /> Уклони научни клуб #{index + 1}
              </Button>
            </React.Fragment>
          )}
        </FormGroup>
      ))}
      <React.Fragment>
        <hr />
        <Button color="success" onClick={() => fields.push({})}>
          <FA icon="s plus" /> Додај још један научни клуб
        </Button>
      </React.Fragment>
    </React.Fragment>
  );
};

const Form3 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>Имплементација пројекта</h2>
    <FieldArray name="eksponati" component={renderEksponati} />
    {formActions({ submitting })}
  </Form>
);

Form3.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form3);
