import React from 'react';
import { Route } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';

const WithMainLayout = ({ component: Component, ...more }) => (
  <Route
    {...more}
    render={props => (
      <MainLayout {...props}>
        <Component {...props} />
      </MainLayout>
    )}
  />
);

// eslint-disable-next-line import/prefer-default-export
export { WithMainLayout };
