import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { Row, Col, Button, Form, FormGroup } from 'reactstrap';
import FA from 'helpers/FA';
import { renderField } from 'helpers/form';
import { required, totalLength, email } from 'helpers/validations';

import renderClanovi from '../renderClanovi';

const renderInstitucije = ({ fields, meta: { error } }) => {
  if (fields.length === 0) fields.push({});
  return (
    <React.Fragment>
      {fields.map((fieldName, index) => (
        <FormGroup tag="fieldset" key={`${fieldName}-${index}`}>
          <h3 style={{ marginBottom: '.5rem' }}>
            Партнерска институција #{index + 1}
          </h3>
          <Row>
            <Col md="6">
              <Field
                name={`${fieldName}.naziv`}
                label="Назив Институције"
                validate={required}
                component={renderField}
              />
            </Col>
            <Col md="6">
              <Field
                name={`${fieldName}.sediste`}
                label="Седиште"
                validate={required}
                component={renderField}
              />
            </Col>
            <Col md="6">
              <Field
                name={`${fieldName}.pib`}
                label="ПИБ"
                type="number"
                validate={[required, totalLength(9)]}
                component={renderField}
              />
            </Col>
            <Col md="6">
              <Field
                name={`${fieldName}.maticniBroj`}
                label="Матични број"
                type="number"
                validate={[required, totalLength(8)]}
                component={renderField}
              />
            </Col>
            <Col md="6">
              <Field
                name={`${fieldName}.odgovornoLice`}
                label="Одговорно лице"
                validate={required}
                component={renderField}
              />
            </Col>
            <Col md="6">
              <Field
                name={`${fieldName}.vebSajt`}
                label="Веб сајт"
                validate={required}
                component={renderField}
              />
            </Col>
            <Col md="6">
              <Field
                name={`${fieldName}.email`}
                label="Имејл"
                type="email"
                validate={[required, email]}
                component={renderField}
              />
            </Col>
          </Row>
          <FieldArray name={`${fieldName}.clanovi`} component={renderClanovi} />
          {fields.length > 1 && (
            <React.Fragment>
              <hr />
              <Button color="danger" onClick={() => fields.remove(index)}>
                <FA icon="s minus-circle" /> Уклони институцију
              </Button>
            </React.Fragment>
          )}
        </FormGroup>
      ))}
      <hr />
      <Button color="success" onClick={() => fields.push({})}>
        <FA icon="s plus" /> Додај још једну институцију
      </Button>
    </React.Fragment>
  );
};

const Form4 = ({
  formActions,
  uSaradjniSaDrugimOrganizacijama,
  handleSubmit,
  submitting,
  back,
}) => (
  <Form onSubmit={handleSubmit}>
    <style>
      {".form-check+.invalid-feedback { display: block }"}
    </style>
    <h2>Партнери</h2>
    <Field
      name="uSaradjniSaDrugimOrganizacijama"
      label="Да ли се пројекат спроводи са другим организацијама?"
      validate={required}
      options={[
        { value: 'true', label: 'Да' },
        { value: 'false', label: 'Не' },
      ]}
      component={renderField}
    />
    {uSaradjniSaDrugimOrganizacijama === 'true' && (
      <FieldArray name="partnerskeInstitucije" component={renderInstitucije} />
    )}
    {formActions({ submitting })}
  </Form>
);

Form4.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(
  connect(state => ({
    uSaradjniSaDrugimOrganizacijama: formValueSelector('prijava')(
      state,
      'uSaradjniSaDrugimOrganizacijama'
    ),
  }))(Form4)
);
