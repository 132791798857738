import React from 'react';
import styled, { css } from 'styled-components';

const StyledDiv = styled.div`
  text-align: center;
`;

const StyledKoraci = styled.div`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
`;

const StyledKorak = styled.div`
  position: relative;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 1rem;
  text-align: center;
  > span {
    display: flex;
    // display: inline-block;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    margin: 0 1.5rem;
    font-size: 2rem;
    background: #f2f2f2;
    border-radius: 50%;
    ${props =>
      props.aktivno &&
      css`
        color: #fff;
        background: var(--primary);
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
      `};
  }
  > p {
    margin: 0.75rem 0 0 0;
    font-weight: 300;
  }
`;

function Koraci({ koraci, aktivno }) {
  return (
    <StyledDiv>
      <StyledKoraci>
        {koraci.map((korak, i) => (
          <StyledKorak key={korak} aktivno={aktivno === i + 1}>
            <span>{i + 1}</span>
            <p>{korak}</p>
          </StyledKorak>
        ))}
      </StyledKoraci>
    </StyledDiv>
  );
}

export default Koraci;
