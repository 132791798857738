import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import FA from 'helpers/FA';
import { setError } from '../actions/common';

const StyledErrorBoundary = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledH1 = styled.h1`
  display: inline-flex;
  align-items: center;
  margin: 0;
  > svg {
    margin-right: 1rem;
    font-size: 4rem;
    color: var(--danger);
  }
`;

const StyledCode = styled.code`
  margin: 1.5rem;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  text-align: center;
  background: #eee;
  border: 1px solid #bbb;
  border-radius: 6px;
`;

// reactjs.org/docs/error-boundaries.html#how-about-event-handlers
class ErrorBoundary extends React.Component {
  static propTypes = {
    error: PropTypes.instanceOf(Error),
    setError: PropTypes.func.isRequired,
  };
  static defaultProps = {
    error: null,
  };
  constructor(props) {
    super(props);
    this.state = {
      countDown: 0,
      countDownTimeoutId: null,
      rebootTimeoutId: null,
    };
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.props.setError(error);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }
  render() {
    if (this.props.error) {
      // You can render any custom fallback UI
      const { message } = this.props.error;
      return (
        <StyledErrorBoundary>
          <StyledH1>
            <FA icon="s frown" /> Грешка у раду
          </StyledH1>
          <StyledCode>{message}</StyledCode>
        </StyledErrorBoundary>
      );
    }
    return this.props.children;
  }
}

export default connect(state => ({ error: state.common.error }), { setError })(
  ErrorBoundary
);
