import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FormGroup, FormText, FormFeedback, Label } from 'reactstrap';
import styled, { keyframes, css } from 'styled-components';
import { flash, pulse, zoomIn } from 'react-animations';
import Dropzone from 'react-dropzone';
import FA from 'helpers/FA';
import { deletePrijavaZakacke, getPrijavaZakacke, postPrijavaZakacka } from 'actions/prijava';

const flashAnimation = keyframes `${flash}`;
const pulseAnimation = keyframes `${pulse}`;
const zoomInAnimation = keyframes `${zoomIn}`;

const StyledDropzone = styled(Dropzone).attrs({ className: 'form-control' })
`
  font-size: 0.875rem;
  font-weight: 300;
  color: #999;
  cursor: pointer;
  > svg {
    display: inline-block;
    margin-right: 0.25rem;
    font-size: 1rem;
    transform: scale(1.25);
    opacity: 0.5;
  }
  &:hover > svg {
    color: var(--primary);
    opacity: 1;
  }
`;

const StyledUl = styled.ul `
  margin-top: 0.5rem;
  padding: 0.5rem 0 0 0;
  list-style: none;
  border-top: 1px dotted #bbb;
  &:empty {
    display: none;
  }
`;

const StyledLi = styled.li `
  display: flex;
  margin-bottom: 0.5rem;
  line-height: 1.1;
  font-size: 0.75rem;
  font-family: var(--font-family-monospace);
  ${props =>
    props.color === 'danger' &&
    css`
      color: #900;
      text-decoration: line-through;
    `};
  svg {
    flex: 0;
    box-sizing: content-box;
    padding-right: 0.5rem;
    color: ${props => `var(--${props.color})`};
    animation: ${props => {
      switch (props.color) {
        case 'success':
          return css`1s ${pulseAnimation}`;
        case 'primary':
          return css`1s ${flashAnimation} infinite`;
        case 'danger':
          return css`1s ${zoomInAnimation}`;
        default:
          return 'none';
      }
    }};
  }
  > span {
    font-weight: 300;
    color: #666;
  }
`;


function removePropertyByValue(obj, valueToRemove) {
  for (const key in obj) {
    if (obj[key]['name'] === valueToRemove) {
      delete obj[key];
      break;
    }
  }
}

class renderZakackeField extends React.Component {
  static propTypes = {
    prijavaId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    maxUploads: 10,
  };

  state = {
    acceptedFiles: [],
    rejectedFiles: [],
    uploadedFiles: [],
    failedFiles: [],
  };

  /**
   * učitaj postojeće zakačke i dodaj ih na uploadedFiles
   * dropzone može da se koristi odmah i pre async componentDidMount
   */
  async componentDidMount() {
    const {
      prijavaId,
      input: { name },
    } = this.props;
    const zakacke = await getPrijavaZakacke(prijavaId, name);
    if (zakacke) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(
        {
          uploadedFiles: [...this.state.uploadedFiles, ...zakacke],
        },
        this.mapUploadedFilesToInput
      );
    }
  }

  removeFile = async (fileName)  => {
    const { uploadedFiles } = this.state;

    const valueToRemove = fileName;
    removePropertyByValue(uploadedFiles, valueToRemove);

    for (let i = uploadedFiles.length - 1; i >= 0; i--) {
      if (!uploadedFiles[i]) {
        uploadedFiles.splice(i, 1);
      }
    }

    const {
      prijavaId,
      input: { name },
    } = this.props;
    const response = await deletePrijavaZakacke(prijavaId, name + '-' + fileName);

    
    this.setState({
      acceptedFiles: [],
      rejectedFiles: [],
      failedFiles: [],
      uploadedFiles: this.state.uploadedFiles,
    },
    this.mapUploadedFilesToInput

    );

  }

  onDrop(acceptedFiles = [], rejectedFiles = []) {
    const {
      input: { name },
      maxUploads,
    } = this.props;
    const { uploadedFiles } = this.state;
    if (acceptedFiles.length <= maxUploads - uploadedFiles.length) {
      this.setState({
        acceptedFiles: [...this.state.acceptedFiles, ...acceptedFiles],
        rejectedFiles: [...this.state.rejectedFiles, ...rejectedFiles],
      });
      acceptedFiles.forEach(file => this.upload(file, name));
    } else {
      alert('You have reached the limit of printing files at a time');
    }
  }

  upload = async file => {
    const {
      prijavaId,
      input: { name },
    } = this.props;
    
    try {
      await postPrijavaZakacka(prijavaId, name + '-' + file.name, file);
      this.setState(
        {
          acceptedFiles: this.state.acceptedFiles.filter(f => f !== file),
          uploadedFiles: [...this.state.uploadedFiles, file],
        },
        // redux form update, setState još ne zna await
        this.mapUploadedFilesToInput
      );
    } catch (e) {
      this.setState({
        acceptedFiles: this.state.acceptedFiles.filter(f => f !== file),
        failedFiles: [...this.state.failedFiles, file],
      });
    }
  };

  mapUploadedFilesToInput() {
    const {
      input: { onChange },
    } = this.props;
    const { uploadedFiles } = this.state;
    onChange(uploadedFiles.map(f => f.name).join(', '));
  }

  render() {
    const {
      accept,
      label,
      hint,
      placeholderText,
      meta: { touched, error },
    } = this.props;
    const {
      uploadedFiles,
      acceptedFiles,
      rejectedFiles,
      failedFiles,
    } = this.state;


    return (
      <FormGroup>
        {label && <Label>{label}</Label>}
        {hint && <Label style={{color:'green'}}>{hint}</Label>}
        <StyledDropzone onDrop={files => this.onDrop(files)} accept={accept}>
          <FA icon="s times" /> {placeholderText ? placeholderText : 'Drag document here'}
        </StyledDropzone>
        {touched &&
          error && <FormFeedback className="d-block">{error}</FormFeedback>}
        <StyledUl>
          {failedFiles.concat(rejectedFiles).map(file => (
            <StyledLi color="danger" key={file.name}>
              <FA icon="s times" /> {file.name}
            </StyledLi>
          ))}
          {acceptedFiles.map(file => (
            <StyledLi color="primary" key={file.name}>
              <FA icon="s arrow-up" /> {file.name}{' '}
              <span>({file.size} bytes)</span>
            </StyledLi>
          ))}
          {uploadedFiles.map(file => (
            <StyledLi color="success" key={file.name} style={{alignItems: 'center'}}>
              <FA icon="s check" /> {file.name} 
              <Button
              className="ml-2"
                color="danger"
                size="sm"
                onClick={() => this.removeFile(file.name)}
              > Remove file
              </Button>
            </StyledLi>
          ))}
        </StyledUl>
      </FormGroup>
    );
  }
}

export default connect(
  state => ({ prijavaId: state.prijava.id }),
  null
)(renderZakackeField);