import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import styled from 'styled-components';
import Header from 'components/Header';
import { logout } from 'react-devise/lib/actions';
import 'react-virtualized/styles.css'; // only needs to be imported once

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainLayout = ({
  currentUser,
  doLogout,
  children,
  location: { state: { notice } = {} },
  match,
}) => (
  <Wrapper>
    <Header
      logout={currentUser.isLoggedIn && doLogout}
      admin={RegExp('/admin').test(match.url)}
      currentUser={currentUser}
    />
    {notice && <Alert color="info">{notice}</Alert>}
    {children}
  </Wrapper>
);

export default connect(
  state => ({
    currentUser: state.currentUser,
  }),
  dispatch => ({
    doLogout: () => logout(dispatch),
  })
)(MainLayout);
