import React from 'react';
import styled, { css } from 'styled-components';
import loaderSVG from 'assets/images/pacman.svg';

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  padding: 0 2rem;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* text-transform: uppercase; */
  color: #666;
  /* background: #fdf6e3;
  border: 1px solid var(--warning);
  border-radius: 12px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1); */
  ${props =>
    props.children &&
    css`
      padding-bottom: 1.5rem;
    `};
`;

export default ({ children }) => (
  <StyledWrap>
    <StyledLoader>
      <img src={loaderSVG} alt="" />
      {children}
    </StyledLoader>
  </StyledWrap>
);
