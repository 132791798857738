// import React from "react";
import styled from 'styled-components';
import { Table } from 'reactstrap';

export default styled(Table).attrs({ size: 'sm' })
`
  margin: 0;
  font-size: 0.875rem;
  /* border-spacing: 0.25rem;
  border-collapse: collapse; */

  thead th {
    font-size: 0.75rem;
  }
  th,
  td {
    padding: 0.125rem;
    border: none !important;
    &.rb,
    &.ukupno {
      text-align: center;
    }
  }
  td {
    vertical-align: top;
    /* padding: 0; */
    border-bottom: 0;
    &.remove {
      text-align: center;
    }
  }

  .table {
    background: transparent;
  }

  .form-group {
    margin: 0;
  }
  .form-control {
    padding: 0.125rem 0.25rem;
    font-size: 0.875rem;
  }
`;