import React from 'react';
import { Provider } from 'react-redux';
import { Router, Switch, Redirect } from 'react-router-dom';
import { withAuth, authRoutes, PrivateRoute } from 'react-devise';
import { getAuthToken } from 'react-devise/lib/actions/authTokenStore';
import { history, store } from './setup';
import { WithMainLayout } from './helpers/routes';
import AdminLayout from './layouts/AdminLayout';
import MainLayout from './layouts/MainLayout';
import NotFound from './layouts/NotFound';
import ErrorBoundary from './components/ErrorBoundary';
import Home from './components/Home';
import AsyncComponent from './components/AsyncComponent';

import Prijava4 from './features/prijave/2019-4';
import Prijava1 from './features/prijave/2021-1';
import Prijava2 from './features/prijave/2021-2';
import Prijava3 from './features/prijave/2021-3';
import Prijava5 from './features/prijave/CitizenScience';

const AsyncAdmin = AsyncComponent(() => import('features/admin'));

class App extends React.Component {
  componentDidMount() {
    const authToken = getAuthToken();
    if (authToken) store.dispatch({ type: 'LOGGED_IN', payload: authToken });
  }

  render() {
    return (
      <Provider store={store}>
        <ErrorBoundary>
          <Router history={history}>
            <Switch>
              {authRoutes({
                wrapper: WithMainLayout,
                notFoundComponent: NotFound,
              })}
              <PrivateRoute
                exact
                path="/admin"
                layout={AdminLayout}
                component={AsyncAdmin}
                // currentUser pri prvom renderu {} ?
                // authorize={currentUser => ({
                //   authorized:
                //     currentUser &&
                //     currentUser.roles &&
                //     currentUser.roles.includes('admin'),
                //   redirectTo: { pathname: '/unauthorized' },
                // })}
              />
              <WithMainLayout exact path="/" component={Home} />
              {/* <PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/prijava1"*/}
              {/*  layout={MainLayout}*/}
              {/*  component={Prijava1}*/}
              {/*/>*/}
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/prijava2"*/}
              {/*  layout={MainLayout}*/}
              {/*  component={Prijava2}*/}
              {/*/>*/}
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/prijava3"*/}
              {/*  layout={MainLayout}*/}
              {/*  component={Prijava3}*/}
              {/*/>*/}
              
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/prijava4"*/}
              {/*  layout={MainLayout}*/}
              {/*  component={Prijava4}*/}
              {/*/>*/}

              {/* <PrivateRoute
                exact
                path="/prijava5"
                layout={MainLayout}
                component={Prijava5}
              /> */} 
              {/* fix za react-devise biblioteku ne radi redirect na auth */}
              <Redirect from="/undefined/login" to="/auth/login" />
              <WithMainLayout component={NotFound} />
            </Switch>
          </Router>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default withAuth(App);
