import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import FA from 'helpers/FA';
import { renderField } from 'helpers/form';
import { required, isValidUrl, totalLength, maxFileCount, number } from 'helpers/validations';
import renderMembers from './renderMembers';
import renderZakackaField from './renderZakackeField';


export default class renderPartners extends React.Component {
  componentDidMount = () => {

    const { fields } = this.props;
    if (fields.length === 0) fields.push({});
  };
  
  render() {
    const {
      fields,
      meta: { error },
      color,
    } = this.props;

    const h3Style = {
      background: color, 
      display: 'inline-block',
      width: '50%',
    };

    return (
      <React.Fragment>
        {fields.map((clan, index) => (
          <FormGroup tag="fieldset" key={clan}>
            <h3 style={h3Style}>
              <FA icon="s user" /> Partner #{index + 1}
            </h3>
            <Row>
              <Col md="4">
                <Field
                  name={`${clan}.name`}
                  label="Name *"
                  type="text"
                  validate={required}
                  component={renderField}
                />
                <Field
                  name={`${clan}.city`}
                  label="City / Place"
                  type="text"
                  component={renderField}
                />
                <Field
                  name={`${clan}.address`}
                  label="Address *"
                  type="text"
                  validate={[required]}
                  component={renderField}
                />
              </Col>
              <Col md="4">
                <Field
                  name={`${clan}.responsiblePerson`}
                  label="Responsible person *"
                  type="text"
                  validate={[required]}
                  component={renderField}
                />
                 <Field
                  name={`${clan}.pib`}
                  label="PIB"
                  type="text"
                  validate={[totalLength(9), number]}
                  component={renderField}
                />
                <Field
                  name={`${clan}.website`}
                  label="Website"
                  type="text"
                  validate={isValidUrl}
                  component={renderField}
                />
              </Col>
              <Col md="4">
                <Field
                    name={`${clan}.annexPartners`}
                    label="Annexes"
                    hint="(for example,  letters of intentions, agreements on cooperation, CVs, publications lists etc.)"
                    accept=".pdf, .docx"
                    placeholderText="Upload file (pdf, Word)"
                    validate={maxFileCount(10)}
                    component={renderZakackaField}
                />
                </Col>
            </Row>

            <FieldArray name={`${clan}.members`} color='#e0ecd4' component={renderMembers} />
            <br />
            <br />
            <br />

            {fields.length > 1 && (
              <Button
                color="danger"
                size="md"
                onClick={() => fields.remove(index)}
              >
                <FA icon="s user-times" /> Remove partner
              </Button>
            )}
            <hr />
          </FormGroup>
        ))}
        <Button color="warning" size="md" onClick={() => fields.push({})}>
          <FA icon="s user-plus" /> Add partner
        </Button>
        {error && error}
      </React.Fragment>
    );
  }
}
