import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Row, Col, Form } from 'reactstrap';
import { required } from 'helpers/validations';
import renderPredmer from '../renderPredmer';
import renderZakackaField from '../renderZakackeField';

const Form3 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>
      <i className="fa fa-info-circle" />
      Имплементација пројекта
    </h2>
    <FieldArray name="predmer" component={renderPredmer} />
    <hr />
    <Row>
      <Col>
        <Field
          name="odlukaOUcescu"
          label="Одлука о учешћу"
          hint="Поље je обавезно, само PDF фајлови"
          accept=".pdf"
          validate={required}
          component={renderZakackaField}
        />
      </Col>
      <Col>
        <Field
          name="tehnickaDokumentacija"
          label="Техничка документација"
          hint="Само PDF фајлови"
          accept=".pdf"
          validate={required}
          component={renderZakackaField}
        />
      </Col>
      <Col>
        <Field
          name="dodatniDokument"
          label="Додатни документ"
          hint="Поље није обавезно, само PDF фајлови"
          accept=".pdf"
          component={renderZakackaField}
        />
      </Col>
    </Row>
    {formActions({ submitting })}
  </Form>
);

Form3.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form3);
