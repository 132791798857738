import React from 'react';
import Prijava from '../Prijava';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
// import Form5 from "./Form5";

export default () => (
  <Prijava
    naslov={
      <h1>
        <small>Јавни позив 2019 - Категорија 4</small>
        Пројекти израде експоната који ће се реализовати у парковима науке
      </h1>
    }
    konkurs="2019-kategorija-4"
    koraci={[
      'Основни подаци',
      'Пројектни тим',
      'Подаци о изради експоната',
      'Сагласност',
    ]}
    forme={[Form1, Form2, Form3, Form4]}
  />
);
