import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  formValueSelector,
  arrayPush,
  reduxForm,
} from 'redux-form';
import { Row, Col, Alert, Form } from 'reactstrap';
import { required } from 'helpers/validations';
import renderZakackaField from '../renderZakackeField';
import PdfDownload from '../PdfDownload';

class renderDokumentacijaUnconnected extends React.Component {
  // componentDidMount + componentDidUpdate
  // u prvom renderu naucniKlubovi su prazni
  static getDerivedStateFromProps(nextProps) {
    const { naucniKlubovi, fields } = nextProps;
    if (fields.length === 0 && naucniKlubovi) {
      for (let i = 0; i < naucniKlubovi.length; i += 1) {
        nextProps.pushToFields({});
      }
    }
    return null;
  }

  state = {};

  render() {
    const { naucniKlubovi, fields } = this.props;
    return (
      <React.Fragment>
        {fields.map((fieldName, index) => (
          <React.Fragment key={`${fieldName}-${index}`}>
            <h3 style={{ marginBottom: '.5rem', paddingBottom: '.5rem' }}>
              {naucniKlubovi[index]}
            </h3>
            <Row>
              <Col md="6">
                <Field
                  name={`${fieldName}.saglasnostKluba`}
                  label="Сагласност клуба"
                  hint="Поље je обавезно, само PDF фајлови"
                  accept=".pdf"
                  validate={required}
                  component={renderZakackaField}
                />
              </Col>
              <Col md="6">
                <Field
                  name={`${fieldName}.dodatniDokument`}
                  label="Додатни документ"
                  hint="Поље није обавезно, само PDF фајлови"
                  accept=".pdf"
                  component={renderZakackaField}
                />
              </Col>
            </Row>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}

const renderDokumentacija = connect(
  state => {
    const naucniKlubovi =
      formValueSelector('prijava')(state, 'aktivnostiNaucnihKlubova') || [];
    return {
      naucniKlubovi: naucniKlubovi.map(n => n.nazivNaucnogKluba),
    };
  },
  dispatch => ({
    pushToFields(val) {
      dispatch(arrayPush('prijava', 'saglasnostNaucnihKlubova', val));
    },
  })
)(renderDokumentacijaUnconnected);

let Form4 = ({ prijava, formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>Сагласност научног клуба</h2>
    <Alert color="info">
      Да бисте завршили пријављивање неопходно је да предлог пројекта преузмете
      у PDF-у и доставите научном клубу за који се пријављујете, ради давања
      сагласности. По добијању сагласности, документ поставите на поље
      &quot;сагласност клуба&quot;. Тек тада можете завршити пријављивање.
    </Alert>
    <PdfDownload color="warning" prijava={prijava}>
      Преузмите пријаву у PDF формату
    </PdfDownload>
    <FieldArray
      name="saglasnostNaucnihKlubova"
      component={renderDokumentacija}
    />
    {formActions({ submitting })}
  </Form>
);
Form4 = connect(state => ({ prijava: state.prijava }))(Form4);

Form4.propTypes = {
  prijava: PropTypes.shape({}).isRequired,
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form4);
