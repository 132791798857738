import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export default class CheckboxGroup extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    label: null,
  };

  field = ({ input, meta, options, label }) => {
    const { name, onChange } = input;
    const { touched, error } = meta;
    const inputValue = input.value;

    const checkboxes = options.map(({ label }, index) => {
      const handleChange = event => {
        const arr = [...inputValue];
        if (event.target.checked) arr.push(label);
        else arr.splice(arr.indexOf(label), 1);
        return onChange(arr);
      };

      const checked = inputValue.includes(label);
      return (
        <FormGroup check key={`checkbox-${label}`}>
          <Label check>
            <Input
              type="checkbox"
              name={`${name}[${index}]`}
              value={label}
              checked={checked}
              onChange={handleChange}
            />
            {label}
          </Label>
        </FormGroup>
      );
    });

    return (
      <FormGroup tag="fieldset">
        {label && <legend className="col-form-label">{label}</legend>}
        {checkboxes}
        {touched &&
          error && <FormFeedback className="d-block">{error}</FormFeedback>}
      </FormGroup>
    );
  };

  render() {
    return <Field {...this.props} type="checkbox" component={this.field} />;
  }
}
