import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * korist: <FA icon="l check" /> -> <FontAwesomeIcon icon={['fal', 'check']} />
 * @param {string} icon
 */
const FA = ({ icon, ...props }) => {
  const split = icon.split(' ');
  const iconTransformed =
    split.length > 1 ? [`fa${split[0]}`, split[1]] : split[0];
  return <FontAwesomeIcon icon={iconTransformed} {...props} />;;
};

FA.propTypes = { icon: PropTypes.string.isRequired };

export default FA;
