import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
// import { removeAuthToken } from 'react-devise';
import { logout } from 'react-devise/lib/actions';
import { Row, Col, Alert, Button } from 'reactstrap';
import FA from 'helpers/FA';
import { history } from 'setup';
import { setError } from 'actions/common';
import { loadPrijava, unloadPrijava, onSubmit } from 'actions/prijava';
import Loader from 'components/Loader';
import Koraci from './Koraci';
import FormWrap from './FormWrap';
import FormActions from './FormActions';

// razbiti u više komponenti?
class Prijava extends React.Component {
  static propTypes = {
    naslov: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    konkurs: PropTypes.string.isRequired,
    koraci: PropTypes.arrayOf(PropTypes.string).isRequired,
    forme: PropTypes.arrayOf(PropTypes.func).isRequired,
    // redux
    loadPrijava: PropTypes.func.isRequired,
    unloadPrijava: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    destroyForm: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    prijava: PropTypes.shape({}),
  };

  static defaultProps = {
    prijava: null,
  };

  state = { korak: 1, poslata: false };

  async componentDidMount() {
    const { konkurs, loadPrijava, logout, setError } = this.props;
    try {
      await loadPrijava(konkurs);
    } catch (e) {
      // unauthorized
      if (e.response && e.response.status === 401) {
        logout();
        history.push('/auth/login');
      } else {
        e.message = 'Учитавање пријаве није успело';
        setError(e);
      }
    }
  }

  componentWillUnmount = () => {
    const { konkurs, unloadPrijava, destroyForm } = this.props;
    unloadPrijava(konkurs);
    destroyForm('prijava');
  };

  // TODO treba da bude akcija/reducer!!
  onSubmit = async prijava => {
    const { konkurs, koraci, logout, setError } = this.props;
    const { korak } = this.state;
    try {
      if (korak < koraci.length) {
        await onSubmit(prijava, konkurs);
        this.setState({ korak: korak + 1 });
      } else {
        // onSubmit ne radi dispatch, zato je ovde poslata: true
        await onSubmit(prijava, konkurs, true);
        this.setState({ poslata: true });
      }
    } catch (e) {
      // unauthorized
      if (e.response && e.response.status === 401) {
        logout();
        history.push('/auth/login');
      } else {
        // e.message = 'Нисмо успели да сачувамо пријаву';
        setError(e);
      }
    }
  };

  back = () => {
    const { korak } = this.state;
    this.setState({ korak: korak - 1 });
  };

  renderFormActions = ({ submitting }) => {
    const { koraci } = this.props;
    const { korak } = this.state;
    return (
      <FormActions>
        {korak !== 1 && (
          <Button color="secondary" onClick={this.back}>
            <FA icon="s hand-point-left" />
            {koraci[korak - 2]}
          </Button>
        )}
        {korak < koraci.length && (
          <Button color="primary" disabled={submitting}>
            {koraci[korak]}
            <FA icon="s hand-point-right" />
          </Button>
        )}
        {korak === koraci.length && (
          <Button type="submit" color="success" disabled={submitting}>
            Submit an application
            <FA icon="s paper-plane" />
          </Button>
        )}
      </FormActions>
    );
  };

  renderForm = () => {
    const { prijava, koraci, forme } = this.props;
    const { korak } = this.state;
    if (!prijava) return <Loader>Loading ...</Loader>;
    const Form = forme[korak - 1];
    return (
      <React.Fragment>
        <Koraci koraci={koraci} aktivno={korak} />
        <FormWrap>
          {
            <Form
              initialValues={prijava.json}
              onSubmit={this.onSubmit}
              // konkurs={konkurs}
              formActions={this.renderFormActions}
            />
          }
        </FormWrap>
      </React.Fragment>
    );
  };

  render = () => {
    const { naslov, prijava } = this.props;
    const { poslata } = this.state;
    return (
      <React.Fragment>
        {naslov}
        {(prijava && prijava.poslata) || poslata ? (
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <Alert color="success">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FA
                    icon="s check"
                    size="2x"
                    style={{ marginRight: '.5rem' }}
                  />
                  Your application has been successfully accepted
                </div>
              </Alert>
            </Col>
          </Row>
        ) : (
          this.renderForm()
        )}
      </React.Fragment>
    );
  };
}

export default connect(
  state => ({ prijava: state.prijava }),
  {
    logout: () => dispatch => logout(dispatch),
    loadPrijava,
    unloadPrijava,
    setError,
    destroyForm: form => dispatch => dispatch(destroy(form)),
  }
)(Prijava);
