import React from 'react';
import { Field } from 'redux-form';
import FA from 'helpers/FA';
import { renderField } from 'helpers/form';
import { required } from 'helpers/validations';
import OkrugliButton from 'components/styled/OkrugliButton';
import AktivnostiTable from './AktivnostiTable';

const iznosAktivnosti = ({ kolicina, jedinicnaVrednost }) => {
  const zbir = (Number(kolicina) || 1) * Number(jedinicnaVrednost);
  return !Number.isNaN(zbir) && zbir;
};

const ukupanIznosAktivnosti = aktivnosti => {
  if (!aktivnosti) return null;
  return aktivnosti.reduce(
    (zbir, aktivnost) => zbir + iznosAktivnosti(aktivnost),
    0
  );
};

export default ({ fields, meta: { error, submitFailed } }) => {
  if (fields.length === 0) fields.push({});

  return (
    <React.Fragment>
      <h4>Предмер материјала и радова</h4>
      <AktivnostiTable>
        <thead>
          <tr>
            <th className="rb">Рб</th>
            <th width="61.8%">Опис</th>
            <th>Количина</th>
            <th>Јединична вредност</th>
            <th colSpan="2" />
          </tr>
        </thead>
        <tbody>
          {fields.map((fieldName, index) => (
            <tr key={`${fieldName}-${index}`}>
              <td className="rb">{index + 1}</td>
              <td>
                <Field
                  name={`${fieldName}.opis`}
                  label={null}
                  validate={required}
                  component={renderField}
                />
              </td>
              <td>
                <Field
                  name={`${fieldName}.kolicina`}
                  label={null}
                  // type="number"
                  validate={required}
                  component={renderField}
                />
              </td>
              <td>
                <Field
                  name={`${fieldName}.jedinicnaVrednost`}
                  label={null}
                  type="number"
                  validate={required}
                  component={renderField}
                />
              </td>
              <td className="ukupno">{iznosAktivnosti(fields.get(index))}</td>
              <td>
                <OkrugliButton
                  color="danger"
                  size="sm"
                  onClick={() => {
                    fields.remove(index);
                  }}
                >
                  <FA icon="s times" />
                </OkrugliButton>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th style={{ textAlign: 'left' }}>
              <OkrugliButton
                color="success"
                size="sm"
                onClick={() => fields.push({})}
              >
                <FA icon="s plus" />
              </OkrugliButton>
            </th>
            <th colSpan="3" style={{ textAlign: 'right' }}>
              Укупно
            </th>
            <th className="ukupno">{ukupanIznosAktivnosti(fields.getAll())}</th>
            <th />
          </tr>
        </tfoot>
      </AktivnostiTable>
    </React.Fragment>
  );
};
