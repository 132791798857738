import React from 'react';
import { withAuth } from 'react-devise';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import FA from 'helpers/FA';
import styled from 'styled-components';
import CitizenScienceLanding from '../features/prijave/CitizenScienceLanding';

const Dokumenta = styled.div`
  text-align: center;
  > ul {
    display: inline-block;
    margin: 0 auto 2rem auto;
    padding: 0;
    list-style: none;
    > li {
      text-align: left;
      border-bottom: 1px solid #ddd;
      > a {
        display: inline-block;
        padding: 0 1rem;
        color: #779;
        > svg {
          margin-right: 0.25rem;
          color: #972600;
        }
      }
    }
  }
`;

const Konkursi = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
  > li {
    padding: 0.5rem;
    > a {
      display: block;
      padding: 1rem 1.5rem;
      line-height: 1.2;
      background: #fff2f2;
      border-radius: 4px;
      transition: ease-in 0.2s all
      &:hover,
      &:focus {
        color: #333;
        background: #cdc;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.075);
        transform: translateY(-0.25rem);
      }
      &:active {
        background: #aba;
        transform: translateY(0);
      }
      > span {
        display: block;
        margin-bottom: 0.5rem;
        color: #666;
        font-weight: bold;
        font-size: 0.875rem;
      }
      small {
        display: block;
        margin-top: 0.25rem;
        color: #888;
      }
    }
  }
`;


const Home = ({ auth: { AuthLinks } }) => (
  <React.Fragment>
    <h1 style={{ textAlign: 'center' }}>Јавни позив 2024</h1>
    <CitizenScienceLanding></CitizenScienceLanding>
    {/*<div style={{ display: 'flex', justifyContent: 'center' }}>*/}
    {/*  <Alert*/}
    {/*    color="info"*/}
    {/*    style={{*/}
    {/*      display: 'flex',*/}
    {/*      alignItems: 'center',*/}
    {/*      justifyContent: 'center',*/}
    {/*    }}*/}
    {/*    >*/}
    {/*    <FA icon="r check" size="2x" style={{ marginRight: '.5rem' }} />*/}
    {/*    Јавни позив је завршен.*/}
    {/*  </Alert>*/}
    {/*</div>*/}
    {/*<Konkursi>*/}
    {/*  <li>*/}
    {/*    <Link to="/prijava1">*/}
    {/*      <span>Категорија&nbsp;1</span> Пројекти промоције науке у научним*/}
    {/*      клубовима*/}
    {/*    </Link>*/}
    {/*  </li>*/}
    {/*  <li>*/}
    {/*    <Link to="/prijava2">*/}
    {/*      <span>Категорија&nbsp;2</span> Пројекти промоције науке*/}
    {/*    </Link>*/}
    {/*  </li>*/}
    {/*  <li>*/}
    {/*    <Link to="/prijava3">*/}
    {/*      <span>Public Call for funding citizen science projects</span>*/}
    {/*    </Link>*/}
    {/*  </li>*/}
    {/*  <li>*/}
    {/*    <Link to="/prijava3">*/}
    {/*      <span>Категорија&nbsp;3</span> Идејна решења за интерактивне експонате*/}
    {/*      у парковима науке*/}
    {/*    </Link>*/}
    {/*  </li>*/}
    {/*  <li>*/}
    {/*    <Link to="/prijava4">*/}
    {/*      <span>Категорија&nbsp;4</span> Пројекти израде експоната који ће се*/}
    {/*      реализовати у парковима науке*/}
    {/*    </Link>*/}
    {/*  </li>*/}
    {/*</Konkursi>*/}

  {/*  <Dokumenta>*/}
  {/*    <ul>*/}
  {/*      <li>*/}
  {/*        <a href="http://prijave.cpn.rs/Oglas_2018.pdf">*/}
  {/*          <FA icon="l file-pdf" /> Оглас јавни позив 2018. категорија 4*/}
  {/*        </a>*/}
  {/*      </li>*/}
  {/*      <li>*/}
  {/*        <a href="http://prijave.cpn.rs/eksponati_2018.pdf">*/}
  {/*          <FA icon="l file-pdf" /> Графичка документација за израду експоната*/}
  {/*          новембар 2018. године*/}
  {/*        </a>*/}
  {/*      </li> */}
  {/*       <li>*/}
  {/*        <a href="http://prijave.cpn.rs/Pravilnik_2018.pdf">*/}
  {/*          <FA icon="l file-pdf" /> Правилник јавног позива*/}
  {/*        </a>*/}
  {/*      </li>*/}
  {/*      <li>*/}
  {/*        <a href="http://prijave.cpn.rs/Uputstvo_2018.pdf">*/}
  {/*          <FA icon="l file-pdf" /> Упутство за попуњавање*/}
  {/*        </a>*/}
  {/*      </li> */}
  {/*  </Dokumenta>*/}
  {/*    </ul>*/}
  {/*  <AuthLinks />*/}
  </React.Fragment>
);

export default withAuth(Home);
