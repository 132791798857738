import React, { useState } from "react";
import { Field } from "redux-form";
import { FormGroup, Label, Input, FormFeedback, FormText } from "reactstrap";
// import ReactDateTime from 'react-datetime';
import styled from "styled-components";
import FA from "helpers/FA";

const StyledCheckLabel = styled(Label)`
  color: #555;
  font-weight: 300;
`;

function renderInput({
  type,
  input,
  invalid,
  options,
  maxLen,
  updateCharacterCount,
  ...rest
}) {

  const passProps = { ...input, ...rest };
  if (invalid) passProps.invalid = true;
  switch (type) {
    case "select":
      return (
        <Input type="select" {...passProps}>
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Input>
      );
    case "select-group":
      return (
        <Input type="select" {...passProps}>
          <option />
          {options.map(({ items, label }) => (
            <optgroup label={label}>
              {items.map(({ value, label: itemLabel }) => (
                <option key={value} value={value}>
                  {itemLabel}
                </option>
              ))}
            </optgroup>
          ))}
        </Input>
      );
    // radio ne zna dal je checked: https://github.com/erikras/redux-form/issues/2880#issuecomment-304034116
    // case 'checkbox':
    case "radio":
      // ako ima više opcija
      if (options) {
        return (
          <React.Fragment>
            {options.map(({ value, label }, index) => (
              <FormGroup key={`${value}-${index}`} check>
                <StyledCheckLabel check>
                  <Input
                    type={type}
                    {...passProps}
                    value={value}
                    checked={
                      input.value !== "" &&
                      input.value !== undefined &&
                      String(input.value) === String(value)
                    }
                  />
                  {label}
                </StyledCheckLabel>
              </FormGroup>
            ))}
          </React.Fragment>
        );
      }
      return (
        <FormGroup check>
          <Label check>
            <Input type={type} {...passProps} />
            {input.label}
          </Label>
        </FormGroup>
      );
    case "checkbox":
      throw new Error("Koristi CheckBoxGroup");
    case "file":
      delete passProps.value;
      return <Input type="file" {...passProps} />;

    default:

    if (options) {
        // redux-form bug, <Field> ne sme dobije type='radio' inače ne zna šta je checked
        return renderInput({
          type: "radio",
          input,
          invalid,
          options,
        });
      }
      return (
        <Input
          type={type}
          onInput={(e) => updateCharacterCount(e, maxLen)}
          {...passProps}
          // onKeyPress={(e) => {
          //   updateCharacterCount(e);
          //   if (e.key === "Enter") e.preventDefault();
          // }}
        />
      );
  }
}

// renderField({ input, label, type, meta: { touched, error, warning } })
export function renderField({
  input,
  label,
  type,
  hint,
  options,
  desc,
  descTitle,
  maxLen,
  meta: { touched, error, warning },
  ...rest
}) {

  const [isLabelVisible, setIsLabelVisible] = useState(true);
  const [charCount, setCharCount] = useState(
    input.value ? Number(maxLen) - input.value.length : Number(maxLen)
  );

  const toggleLabelVisibility = () => {
    setIsLabelVisible(!isLabelVisible);
  };

  // Function to update the character count
  const updateCharacterCount = (event, max) => {

    const text = Number(max) - event.target.value.length;
    setCharCount(text);
    // You can also limit the input length here if needed, e.g., passProps.maxLength = 100;
  };

  const passProps = {
    input,
    label,
    type,
    options,
    descTitle,
    desc,
    maxLen,
    updateCharacterCount,
    ...rest,
  };

  const labelJSX = isLabelVisible ? <div><Label style={{color:'green'}}>{descTitle}</Label><FormText color='black;'>{desc}</FormText></div> : <Label style={{color:'green'}}>{descTitle}</Label>;

  if (touched && error) passProps.invalid = true;

  switch (type) {
    case "radio":
    case "checkbox":
      // ako postoji samo jedna opcija
      if (!options) passProps.options = [{ value: 1, label }];
      // FormFeedback mora da ima posebnu klasu da bi se prikazao,
      // inače je display: none; ako nije uz <input class="is-invalid" />
      return (
        <FormGroup tag="fieldset">
          {options && <Label>{label}</Label>}
          {hint && <Label style={{fontSize:"1rem"}}>{hint}</Label>}
          {renderInput(passProps)}
          {passProps.invalid && (
            <FormFeedback className="d-block">{error}</FormFeedback>
          )}
        </FormGroup>
      );
    default:
      return (
        <FormGroup>
          {label && <Label style={{textWrap:'nowrap', fontSize:"1rem"}}>{label}</Label>}
          <br />
          {descTitle !== undefined ? labelJSX : ''}
          {desc && (
            <Label
              onClick={toggleLabelVisibility}
              style={{ cursor: "pointer", padding: "10px", color: "red" }}
            >
              { isLabelVisible ? <FA icon="minus" /> : <FA icon="plus" /> }
            </Label>
          )}
          {hint && <Label>{hint}</Label>}
          {renderInput(passProps)}
          {maxLen && <span style={{ float: "right", }}>Characters Left: {charCount}</span>}
          {passProps.invalid && <FormFeedback>{error}</FormFeedback>}
        </FormGroup>
      );
  }
}

export const renderFields = (fields) =>
  Object.keys(fields).map((key) => (
    <Field key={key} name={key} {...fields[key]} component={renderField} />
  ));

// export const DateTime = ({
//   input,
//   meta: { touched, invalid, error },
//   label,
//   hint,
//   type = 'dateTime',
//   ...rest
// }) => (
//   // console.log(input, meta);
//   <FormGroup>
//     {label && <Label>{label}</Label>}
//     <ReactDateTime
//       {...input}
//       {...rest}
//       invalid={touched && invalid}
//       // inputProps={{ type: 'date' }}
//       utc
//       dateFormat="DD-MM-YYYY"
//       timeFormat={type === 'dateTime'}
//       closeOnSelect={type !== 'dateTime'}
//     />
//     {touched && invalid && <FormFeedback>{error}</FormFeedback>}
//     {hint && <FormText>{hint}</FormText>}
//   </FormGroup>
// );
