import React from 'react';
import Prijava from '../Prijava';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';

export default () => (
  <Prijava
    naslov={
      <h1>
        <small>Јавни позив 2023 - Категорија 1</small>
        Пројекти промоције науке у научним клубовима
      </h1>
    }
    konkurs="2023-kategorija-1"
    koraci={['Општи подаци', 'Пројектни тим', 'Имплементација', 'Сагласност']}
    forme={[Form1, Form2, Form3, Form4]}
  />
);
