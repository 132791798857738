import axios from 'axios';
import { getBearerToken } from 'react-devise';
import { API_HOST } from '../constants/config';

export default {
    root: API_HOST,
    config: () => ({
        headers: {
            Accept: 'application/json',
            Authorization: getBearerToken(),
        },
    }),
    async get(path, config = this.config()) {
        const response = await axios.get(`${this.root}/${path}`, config);
        return response.data;
    },    
    async delete(path, config = this.config()) {
      const response = await axios.delete(`${this.root}/${path}`, config);
      return response.data;
    },
    async post(path, data, config = this.config()) {
        const response = await axios.post(`${this.root}/${path}`, data, config);
        return response.data;
    },
    async patch(path, data) {
        const response = await axios.patch(
            `${this.root}/${path}`,
            data,
            this.config()
        );
        return response.data;
    },

    // prijave
    async getPrijava(konkurs) {
        return this.get(`prijava?konkurs=${konkurs}`);
    },
    async patchPrijava(prijava) {
        return this.patch('prijava', { prijava });
    },

    // zakacke JSON
    async getPrijavaZakacke(id, identifikator) {
        return this.get(`zakacke?prijava_id=${id}&identifikator=${identifikator}`);
    },
    async deletePrijavaZakacke(id, identifikator) {
        return this.delete(`zakacke?prijava_id=${id}&identifikator=${identifikator}`);
    },
    async getUserConfirmation(token) {
        return this.get(`auth/confirmation${token}`);
    },
    /**
     * upload Zakacka
     * @param {int} id
     * @param {string} identifikator
     * @param {File} file
     */
    async postPrijavaZakacka({ zakacka }) {
        const { prijava_id: id, identifikator, fajl } = zakacka;
        const formData = new FormData();
        formData.append('zakacka[prijava_id]', id);
        formData.append('zakacka[identifikator]', identifikator);
        formData.append('zakacka[fajl]', fajl, fajl.name);
        const config = {
            headers: {
                ...this.config().headers,
                'Content-Type': 'multipart/form-data',
            },
        };
        return this.post('zakacke', formData, config);
    },

    async getPrijavaZakacka(id, filename = id, inline = false) {
        const data = await this.get(`zakacke/${id}`, {
            headers: {
                ...this.config().headers,
            },
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        if (!inline) link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        // delay za Firefox
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data);
        }, 100);
        return Promise.resolve();
    },

    async getPrijavaPDF(prijava, inline = false) {
        const { id, konkurs } = prijava;
        const data = await this.get(`pdfs/${id}`, {
            headers: {
                Accept: 'application/pdf',
                Authorization: getBearerToken(),
            },
            responseType: 'blob',
        });
        // const url = window.URL.createObjectURL(new Blob([data]), {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        if (!inline) link.setAttribute('download', `${id}-${konkurs}.pdf`);
        document.body.appendChild(link);
        link.click();
        // delay za Firefox
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data);
        }, 100);
        return Promise.resolve();
    },

    async getIzvestaj(inline = false) {
        const data = await this.get(`admin/izvestaj.xls`, {
            headers: {
                // Accept: 'application/pdf',
                Authorization: getBearerToken(),
            },
            responseType: 'blob',
        });
        // const url = window.URL.createObjectURL(new Blob([data]), {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        if (!inline) link.setAttribute('download', `izvestaj.xls`);
        document.body.appendChild(link);
        link.click();
        // delay za Firefox
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data);
        }, 100);
        return Promise.resolve();
    },
};