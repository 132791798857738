import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import FA from 'helpers/FA';
import Api from 'actions/api';

export default class PdfDownload extends React.Component {
  static propTypes = {
    prijava: PropTypes.shape({}).isRequired,
    inline: PropTypes.bool,
  };

  static defaultProps = {
    inline: false,
  };

  state = {
    loading: false,
  };

  onClick = async () => {
    const { prijava, inline } = this.props;
    this.setState({ loading: true });
    await Api.getPrijavaPDF(prijava, inline);
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    const { inline, ...passProps } = this.props;
    return (
      <Button {...passProps} onClick={this.onClick} disabled={loading}>
        {loading ? (
          <FA icon="s file-pdf" spin fixedWidth />
        ) : (
          <FA icon="s file-pdf" fixedWidth />
        )}{' '}
        {this.props.children}
      </Button>
    );
  }
}
