function empty(data) {
  if (typeof data === "object") {
    // null je Object!
    return !data || Object.keys(data).length === 0;
  }
  if (data && typeof data.length !== "undefined") {
    return data.length === 0;
  }
  return !data;
}

export const required = (value) =>
  empty(value) ? "Field is required" : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `You can enter maximum of ${max} characters`
    : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min
    ? `You must enter minimum of ${min} characters`
    : undefined;

export const totalLength = (length) => (value) =>
  value && value.length !== length
    ? `You must enter exactly ${length} characters`
    : undefined;

export const number = (value) =>
  value && Number.isNaN(Number(value)) ? "Must be number" : undefined;

export const maxValue = (max) => (value) =>
  value && Number(value) > max ? `Maximum: ${max}` : undefined;

export const minValue = (min) => (value) =>
  value && Number(value) < min ? `Minimum: ${min}` : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const isValidUrl = (value) =>
  value &&
  !/^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/.test(value)
    ? "Invalid URL"
    : undefined;

// const alphaNumeric = value =>
//   value && /[^a-zA-Z0-9 ]/i.test(value)
//     ? 'Only alphanumeric characters'
//     : undefined;

export const phoneNumber = (value) =>
  value && !/^[-+\/\\\s\d]*$/i.test(value) ? "Invalid phone number" : undefined;

export const maxFileCount = (max) => (files) =>
  files && files.split(", ").length > max
    ? `Maximum allowed files: ${max}`
    : undefined;
