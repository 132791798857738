import React from 'react';
import PropTypes from 'prop-types';
import { FormSection, Field, reduxForm } from 'redux-form';
import { Row, Col, Form, FormGroup } from 'reactstrap';
// import CheckboxGroup from 'components/CheckboxGroup';
import { renderField } from 'helpers/form';
import { required, maxLength, number} from 'helpers/validations';

const Form1 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>
      <i className="fa fa-info-circle" />
      Project Idea and Team
    </h2>
    <hr />
    <FormSection name="project">
      <FormGroup tag="fieldset">
        <Row>
          <Col md="6">
            <Field
              name="title"
              label="Proposal title *"
              type="text"
              maxLen="150"
              validate={[required, maxLength(150)]}
              component={renderField}
            />
            <Field
              name="keywords"
              label="Keywords *"
              type="text"
              maxLen="150"
              validate={[required, maxLength(150)]}
              component={renderField}
            />
            <Field
              name="summary"
              label="Summary *"
              descTitle="Provide a brief overview of your proposed project"
              type="textarea"
              maxLen="1000"
              validate={[required, maxLength(1000)]}
              component={renderField}
            />
            <Field
              name="goals"
              label="Goals of the project *"
              type="textarea"
              maxLen="1000"
              validate={[required, maxLength(1000)]}
              component={renderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="description"
              label="Project description *"
              descTitle="Answer the following questions:"
              desc="How is your project different from others? 
              What new knowledge will it contribute to society? 
              What is the primary methodology approach? 
              Who are the key stakeholders?"
               type="textarea"
               maxLen="3000"
              validate={[required, maxLength(3000)]}
              component={renderField}
            />
            <Field
              name="team"
              label="Project team (description) *"
              descTitle="Answer the following questions:"
              desc="Who are the core members of your team? 
              What are their relevant skills and experience?
              What expertise are you missing? 
              Does the team have experience in research activities, managing communities, citizen science, etc.?
              Have they identified relevant expertise gaps and ways to fill those gaps?
              Does the team involve experts from different disciplines?
              How is or will your project team (including involved citizens) be inclusive and diverse?"
              type="textarea"
              maxLen="2000"
              validate={[required, maxLength(2000)]}
              component={renderField}
            />
          </Col>
        </Row>
      </FormGroup>
    </FormSection>
    {formActions({ submitting })}
  </Form>
);

Form1.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form1);
