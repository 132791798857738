import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  margin: 1rem 0 0 0;
  padding: 1rem 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  button:first-child:not(:last-child) {
    > svg {
      margin-right: 0.5rem;
    }
  }

  button:last-child,
  button:first-child:last-child {
    margin-left: auto;
    > svg {
      margin-left: 0.5rem;
    }
  }
`;

export default function FormActions({ children }) {
  return <StyledDiv>{children}</StyledDiv>;
}
