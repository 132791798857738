import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';
import { Form } from 'reactstrap';
import renderPartners from '../renderPartners';

const Form5 = ({ formActions, handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <h2>Partners</h2>
    <hr />
    <FieldArray name="partners" color="#ffc107" component={renderPartners} />
    {formActions({ submitting })}
  </Form>
);

Form5.propTypes = {
  formActions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'prijava', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate,
})(Form5);
